/**
 * @since 2023-05-14
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import InteractiveIcon from "./InteractiveIcon";

export default InteractiveIcon;
