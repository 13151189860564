/**
 * @since 2023-05-12
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { BiPencil } from "react-icons/bi";
import "./DisplayBox.scss";

const DisplayBox = ({ name, value, onClick, nRows = 1, disabled = false }) => {
  const isMultiline = nRows > 1;
  const inputHeight = isMultiline ? 50 + 14 * nRows : 40;

  return (
    <div
      key={`DisplayBox.${name}`}
      className="display-box"
      style={{ height: inputHeight, cursor: "pointer" }}
      onClick={disabled ? null : onClick}
    >
      <div
        key={`DisplayBox.${name}.Container`}
        className="display-box__container"
      >
        <span className="display-box__content">{value}</span>
        <BiPencil className="display-box__icon" />
      </div>
    </div>
  );
};
export default DisplayBox;
