/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #191a1d;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 420px;
  background-color: #202126;
  padding: 20px;
  border-radius: 5px;
`;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
`;

export const Input = styled.input`
  margin: 10px 0;
  padding: 10px;
  background-color: #ffffff0d;
  border: none;
  color: #fff;
  border-radius: 3px;
`;

export const Button = styled.button`
  margin: 10px 0;
  padding: 10px;
  background-color: #78bcfb;
  border: none;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  cursor: pointer;

  &:hover {
    background-color: #78bcfb;
  }
`;

export const ToggleButton = styled.button`
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    color: #ecf0f1;
  }
`;

export const ErrorMessage = styled.p`
  color: #e74c3c;
  font-size: 14px;
  text-align: center;
  margin: 0;
`;
