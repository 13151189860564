/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Report from "components/Shared/Report";
import TextWait from "components/Shared/TextWait";

import { loadPremiumPreview } from "dataHandling/premium";
import { useEffect, useState } from "react";

const PreviewChart = ({ email, portfolioSettings }) => {
  const [status, setStatus] = useState("");
  const [portfolioId, setPortfolioId] = useState(
    portfolioSettings.Settings.PortfolioId
  );
  const [previewData, setPreviewData] = useState();

  useEffect(() => {
    setPortfolioId(portfolioSettings.Settings.PortfolioId);
    fetchData();
  }, [portfolioSettings, email]);

  const fetchData = async () => {
    if (!portfolioId) return;
    const newPreviewData = await loadPremiumPreview(email, portfolioId);
    setPreviewData(newPreviewData);
    setStatus("OK");
  };

  return (
    <>
      {status === "WAITING" && <TextWait />}
      {status === "OK" && (
        <Report
          email={email}
          moduleType={"PREMIUM"}
          moduleId={portfolioSettings.Settings.PortfolioId}
          reportData={previewData}
        />
      )}
    </>
  );
};

export default PreviewChart;
