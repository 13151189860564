/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ItemChartSimStatsCollector from "./ItemChartSimStatsCollector";

export default ItemChartSimStatsCollector;
