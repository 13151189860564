/**
 * @since 2024-06-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ItemTableFactorExposureOverall from "./ItemTableFactorExposureOverall";

export default ItemTableFactorExposureOverall;
