/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ImportedBuilder from "components/ImportedBuilder";
import NavBar from "components/NavBar";
import MenuItem from "components/NavBar/MenuItem";
import PortfolioBuilder from "components/PortfolioBuilder";
import PremiumBuilder from "components/PremiumBuilder";
import ErrorPage from "components/Shared/UI/ErrorPage";
import SignalGenerator from "components/SignalGenerator";
import StrategyBuilder from "components/StrategyBuilder";
import { MENU_ITEMS, VIEW_DEFAULT } from "constants";
import { loadMenuItems } from "dataHandling/general";
import {
  createImportedSettings,
  deleteImportedSettings,
  duplicateImportedSettings,
} from "dataHandling/imported";
import {
  createPortfolioSettings,
  deletePortfolioSettings,
  duplicatePortfolioSettings,
} from "dataHandling/portfolio";
import {
  createSignalSettings,
  deleteSignalSettings,
  duplicateSignalSettings,
} from "dataHandling/signal";
import {
  createStrategySettings,
  deleteStrategySettings,
  duplicateStrategySettings,
} from "dataHandling/strategy";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebaseConfig from "firebaseConfig";
import { useEffect, useState } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Cookies from "universal-cookie";
import "../App/App.scss";
import "./Dashboard.scss";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const auth = firebase.auth();

function Dashboard(props) {
  //region States & Consts

  const email = localStorage.getItem("email");
  const cookies = new Cookies();
  const token = cookies.get("token");
  const [isLoading, setIsLoading] = useState(true);
  const configuration = JSON.parse(localStorage.getItem("configuration"));
  const [selectedMenuItem, setSelectedMenuItem] = useState(
    new MenuItem(null, null, null, null)
  );
  const [menuItems, setMenuItems] = useState(null);
  const location = useLocation();
  const [currentLocation, setCurrentLocation] = useState(location);
  const navigate = useNavigate();
  const [isNavBarVisible, setIsNavBarVisible] = useState(true);

  //endregion

  //region Use Effects

  useEffect(() => {
    if (!token || !email) {
      navigate("/login", { replace: true });
      return;
    }
    (async () => {
      setIsLoading(true);
      const newMenuItems = await loadMenuItems(email);
      localStorage.setItem("menuItems", JSON.stringify(newMenuItems));
      setMenuItems(newMenuItems);
      await loadFromUrl(location, newMenuItems);
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (location.pathname === currentLocation) {
      return;
    }
    if (!isLoading) {
      setCurrentLocation(location.pathname);
      loadFromUrl(location);
      setIsLoading(false);
    }
  }, [location]);

  //endregion

  //region Methods

  function findNext(type, element) {
    const listItems = Object.keys(menuItems[type]).filter(
      (item) => item !== element
    );
    return listItems.length > 0 ? listItems[0] : null;
  }

  const onNavBarToggled = async (isNavBarVisible) => {
    setIsNavBarVisible(isNavBarVisible);
  };

  const handleCreateNewImported = async () => {
    const dictData = await createImportedSettings(email);
    await handleMenuItemSelected(
      new MenuItem("Imported", dictData["importedId"], dictData["importedName"])
    );
  };

  const getMenuItemFromLocation = (newLocation, newMenuItems) => {
    const items = newMenuItems ? newMenuItems : menuItems;
    const loc = newLocation ? newLocation : location;
    if (!loc.pathname || !items) {
      return null;
    }
    const parts = loc.pathname.split("/");
    // e.g. "/", "imported", "IMP-20231026-074039", "settings"
    if (parts.length < 3) {
      return null;
    }
    const module = parts[1].toUpperCase(); // e.g. IMPORTED
    const itemId = parts[2]; // e.g. IMP-20231026-074039
    const itemView =
      parts.length > 3 ? parts[3].toUpperCase() : selectedMenuItem.ItemView; // e.g. settings
    const dictItems = items[MENU_ITEMS[module]];
    if (!dictItems) {
      return null;
    }
    const itemName = Object.keys(dictItems).find(
      (key) => dictItems[key] === itemId
    ); // e.g. Imported 01
    if (!itemName) {
      return null;
    }
    return new MenuItem(module, itemId, itemName, itemView);
  };

  const handleCreateNewPortfolio = async () => {
    const dictData = await createPortfolioSettings(email);
    await handleMenuItemSelected(
      new MenuItem(
        "Portfolio",
        dictData["portfolioId"],
        dictData["portfolioName"]
      )
    );
  };

  const handleCreateNewSignal = async () => {
    const dictData = await createSignalSettings(email);
    await handleMenuItemSelected(
      new MenuItem("Signal", dictData["signalId"], dictData["signalName"])
    );
  };

  const handleCreateNewStrategy = async () => {
    const dictData = await createStrategySettings(email);
    await handleMenuItemSelected(
      new MenuItem("Strategy", dictData["strategyId"], dictData["strategyName"])
    );
  };

  const handleDeleteImported = async (importedId) => {
    const itemClosest = findNext("Imported", selectedMenuItem.ItemName);
    await deleteImportedSettings(email, importedId);
    if (itemClosest) {
      // Load closest item
      await handleMenuItemSelected(
        new MenuItem("Imported", menuItems.Imported[itemClosest], itemClosest)
      );
    } else {
      // Reset menu
      await loadFromMenu(null, true);
    }
  };

  const handleDeletePortfolio = async (portfolioId) => {
    const itemClosest = findNext("Portfolios", selectedMenuItem.ItemName);
    await deletePortfolioSettings(email, portfolioId);
    if (itemClosest) {
      // Load closest item
      await handleMenuItemSelected(
        new MenuItem(
          "Portfolio",
          menuItems.Portfolios[itemClosest],
          itemClosest
        )
      );
    } else {
      // Reset menu
      await loadFromMenu(null, true);
    }
  };

  const handleDeleteSignal = async (signalId) => {
    const itemClosest = findNext("Signals", selectedMenuItem.ItemName);
    await deleteSignalSettings(email, signalId);
    if (itemClosest) {
      // Load closest item
      await handleMenuItemSelected(
        new MenuItem("Signal", menuItems.Signals[itemClosest], itemClosest)
      );
    } else {
      // Reset menu
      await loadFromMenu(null, true);
    }
  };

  const handleDeleteStrategy = async (strategyId) => {
    const itemClosest = findNext("Strategies", selectedMenuItem.ItemName);
    await deleteStrategySettings(email, strategyId);
    if (itemClosest) {
      // Load closest item
      await handleMenuItemSelected(
        new MenuItem("Strategy", menuItems.Strategies[itemClosest], itemClosest)
      );
    } else {
      // Reset menu
      await loadFromMenu(null, true);
    }
  };

  const handleDuplicateImported = async (importedId) => {
    const dictData = await duplicateImportedSettings(email, importedId);
    await handleMenuItemSelected(
      new MenuItem("Imported", dictData["importedId"], dictData["importedName"])
    );
  };

  const handleDuplicatePortfolio = async (portfolioId) => {
    const dictData = await duplicatePortfolioSettings(email, portfolioId);
    await handleMenuItemSelected(
      new MenuItem(
        "Portfolio",
        dictData["portfolioId"],
        dictData["portfolioName"]
      )
    );
  };

  const handleDuplicateSignal = async (signalId) => {
    const dictData = await duplicateSignalSettings(email, signalId);
    await handleMenuItemSelected(
      new MenuItem("Signal", dictData["signalId"], dictData["signalName"])
    );
  };

  const handleDuplicateStrategy = async (strategyId) => {
    const dictData = await duplicateStrategySettings(email, strategyId);
    await handleMenuItemSelected(
      new MenuItem("Strategy", dictData["strategyId"], dictData["strategyName"])
    );
  };

  const handleMenuItemSelected = async (newMenuItem) => {
    // Update selected item
    if (!newMenuItem.ItemId) {
      return;
    }
    // Reload menu
    await reloadData();
    setSelectedMenuItem(newMenuItem);
    const newLocation = newMenuItem.getUrl();
    setCurrentLocation(newLocation);
    navigate(newLocation, { replace: true, relative: true });
  };

  const handleRename = async () => {
    // Reload data
    const newMenuItems = await reloadData();
    // Reload entries
    const dictIds = newMenuItems[MENU_ITEMS[selectedMenuItem.Module]];
    const newName = Object.keys(dictIds).find(
      (key) => dictIds[key] === selectedMenuItem.ItemId
    );
    // Refresh
    setSelectedMenuItem(
      new MenuItem(
        selectedMenuItem.Module,
        selectedMenuItem.ItemId,
        newName,
        selectedMenuItem.ItemView
      )
    );
  };

  const handleSettingsChanged = async () => {
    const newMenuItems = await reloadData();
    setMenuItems(newMenuItems);
  };

  const loadFromMenu = async (newMenuItems = null, reload = false) => {
    let menu = newMenuItems ? newMenuItems : menuItems;
    if (reload) {
      menu = await reloadData();
    }
    if (!menu) {
      return;
    }
    let newModule = "";
    let newItemName = "";
    let newItemId = "";
    if (menu.Portfolios && Object.keys(menu.Portfolios).length > 0) {
      newModule = "PORTFOLIO";
      newItemName = Object.keys(menu.Portfolios)[0];
      newItemId = menu.Portfolios[newItemName];
    } else if (menu.Strategies && Object.keys(menu.Strategies).length > 0) {
      newModule = "STRATEGY";
      newItemName = Object.keys(menu.Strategies)[0];
      newItemId = menu.Strategies[newItemName];
    } else if (menu.Signals && Object.keys(menu.Signals).length > 0) {
      newModule = "SIGNAL";
      newItemName = Object.keys(menu.Signals)[0];
      newItemId = menu.Signals[newItemName];
    } else if (menu.Imported && Object.keys(menu.Imported).length > 0) {
      newModule = "IMPORTED";
      newItemName = Object.keys(menu.Imported)[0];
      newItemId = menu.Imported[newItemName];
    } else if (menu.Premium && Object.keys(menu.Premium).length > 0) {
      newModule = "PREMIUM";
      newItemName = Object.keys(menu.Premium)[0];
      newItemId = menu.Premium[newItemName];
    }
    const menuView = VIEW_DEFAULT[newModule];
    handleMenuItemSelected(
      new MenuItem(newModule, newItemId, newItemName, menuView)
    );
  };

  const loadFromUrl = async (newLocation = null, newMenuItems = null) => {
    const newMenuItem = getMenuItemFromLocation(newLocation, newMenuItems);
    if (newMenuItem) {
      handleMenuItemSelected(newMenuItem);
    } else {
      //navigate(`404`, { replace: true });
      await loadFromMenu(null, true);
    }
  };

  const reloadData = async () => {
    const newMenuItems = await loadMenuItems(email);
    setMenuItems(newMenuItems);
    return newMenuItems;
  };

  //endregion

  return (
    <>
      {!isLoading && (
        <>
          <div className="dashboard">
            <NavBar
              email={email}
              menuItems={menuItems}
              selectedMenuItem={selectedMenuItem}
              onItemSelected={handleMenuItemSelected}
              onDeleteImported={handleDeleteImported}
              onDeletePortfolio={handleDeletePortfolio}
              onDeleteSignal={handleDeleteSignal}
              onDeleteStrategy={handleDeleteStrategy}
              onDuplicateImported={handleDuplicateImported}
              onDuplicatePortfolio={handleDuplicatePortfolio}
              onDuplicateSignal={handleDuplicateSignal}
              onDuplicateStrategy={handleDuplicateStrategy}
              onNewImported={handleCreateNewImported}
              onNewPortfolio={handleCreateNewPortfolio}
              onNewSignal={handleCreateNewSignal}
              onNewStrategy={handleCreateNewStrategy}
              isNavBarVisible={isNavBarVisible}
              onNavBarToggled={onNavBarToggled}
              onSettingsChanged={handleSettingsChanged}
            />

            <div className="dashboard-container">
              <Routes>
                <Route element={<Outlet />}>
                  <Route
                    path={`/strategy/${selectedMenuItem.ItemId}/*`}
                    element={
                      <StrategyBuilder
                        email={email}
                        menuItem={
                          selectedMenuItem.Module === "STRATEGY"
                            ? selectedMenuItem
                            : null
                        }
                        listLockedIds={menuItems.Locked}
                        listStrategyNames={
                          menuItems.Strategies
                            ? Object.keys(menuItems.Strategies)
                            : []
                        }
                        listStrategyFrequencies={
                          menuItems.User["strategyFrequencies"]
                        }
                        configuration={configuration}
                        onStrategyNameChange={handleRename}
                        isNavBarVisible={isNavBarVisible}
                      />
                    }
                  />
                  <Route
                    path={`/premium/${selectedMenuItem.ItemId}/*`}
                    element={
                      <PremiumBuilder
                        email={email}
                        menuItem={
                          selectedMenuItem.Module === "PREMIUM"
                            ? selectedMenuItem
                            : null
                        }
                        configuration={configuration}
                        isNavBarVisible={isNavBarVisible}
                      />
                    }
                  />
                  <Route
                    path={`/imported/${selectedMenuItem.ItemId}/*`}
                    element={
                      <ImportedBuilder
                        email={email}
                        menuItem={
                          selectedMenuItem.Module === "IMPORTED"
                            ? selectedMenuItem
                            : null
                        }
                        listImportedNames={
                          menuItems.Imported
                            ? Object.keys(menuItems.Imported)
                            : []
                        }
                        configuration={configuration}
                        onImportedNameChange={handleRename}
                        isNavBarVisible={isNavBarVisible}
                      />
                    }
                  />
                  <Route
                    path={`/portfolio/${selectedMenuItem.ItemId}/*`}
                    element={
                      <PortfolioBuilder
                        email={email}
                        menuItem={
                          selectedMenuItem.Module === "PORTFOLIO"
                            ? selectedMenuItem
                            : null
                        }
                        listLockedIds={menuItems.Locked}
                        listPortfolioNames={
                          menuItems.Portfolios
                            ? Object.keys(menuItems.Portfolios)
                            : []
                        }
                        configuration={configuration}
                        dictStrategies={menuItems.Strategies}
                        dictImported={menuItems.Imported}
                        dictPremium={menuItems.Premium}
                        onPortfolioNameChange={handleRename}
                        isNavBarVisible={isNavBarVisible}
                      />
                    }
                  />
                  <Route
                    path={`/signal/${selectedMenuItem.ItemId}/*`}
                    element={
                      <SignalGenerator
                        email={email}
                        menuItem={
                          selectedMenuItem.Module === "SIGNAL"
                            ? selectedMenuItem
                            : null
                        }
                        listSignalNames={
                          menuItems.Signals
                            ? Object.keys(menuItems.Signals)
                            : []
                        }
                        listLockedIds={menuItems.Locked}
                        configuration={configuration}
                        dictPortfolios={menuItems.Portfolios}
                        onSignalNameChange={handleRename}
                        onSettingsRefresh={handleSettingsChanged}
                        isNavBarVisible={isNavBarVisible}
                      />
                    }
                  />
                  <Route
                    path="/404"
                    element={<ErrorPage errorMessage="Not found" />}
                  />
                </Route>
              </Routes>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
