/**
 * @since 2023-05-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";

// base types
interface BaseReceivedResponse<
  SuccessfulResponseBodyType,
  FailedResponseBodyType
> {
  meta: Response;
  body: SuccessfulResponseBodyType | FailedResponseBodyType;
}
interface BaseServerErrorResponse<FailedResponseBodyType> {
  body: FailedResponseBodyType;
}

type BaseResponse<SuccessfulResponseBodyType, FailedResponseBodyType> =
  | BaseReceivedResponse<SuccessfulResponseBodyType, FailedResponseBodyType>
  | BaseServerErrorResponse<FailedResponseBodyType>;

// common types
interface SuccessfulResponseBody {
  success: true;
}
interface FailedResponseBody {
  success: false;
  error: string;
}

// local types
interface ResetPassSuccessfulResponseBody {
  success: true;
  message: string;
}
const resetPassword = async (
  email: string
): Promise<
  BaseResponse<ResetPassSuccessfulResponseBody, FailedResponseBody>
> => {
  try {
    let response = await fetch(`${BASE_BACKEND_URL}/user/reset-password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    const responseObject: BaseResponse<
      ResetPassSuccessfulResponseBody,
      FailedResponseBody
    > = {
      meta: response,
      body: await response.json(),
    };
    return responseObject;
  } catch (error) {
    console.error(error);
    const failedResponse: BaseServerErrorResponse<FailedResponseBody> = {
      body: {
        success: false,
        error:
          "Something went wrong. Please contact us support@centrallimit.ai",
      },
    };
    return failedResponse;
  }
};

interface LoginSuccessfulResponseBody extends SuccessfulResponseBody {
  accessToken: string;
  expiresIn: string;
  refreshToken: string;
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;
}

const login = async (
  email: string,
  password: string
): Promise<BaseResponse<LoginSuccessfulResponseBody, FailedResponseBody>> => {
  try {
    const response: Response = await fetch(`${BASE_BACKEND_URL}/user/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });
    const responseObject: BaseResponse<
      LoginSuccessfulResponseBody,
      FailedResponseBody
    > = {
      meta: response,
      body: await response.json(),
    };
    return responseObject;
  } catch (error) {
    console.error(error);
    const failedResponse: BaseServerErrorResponse<FailedResponseBody> = {
      body: {
        success: false,
        error:
          "Something went wrong. Please contact us support@centrallimit.ai.",
      },
    };
    return failedResponse;
  }
};

interface RegisterResponseBody extends SuccessfulResponseBody {
  message: string;
}
interface BadRegisterResponseBody extends FailedResponseBody {}

const register = async (
  email: string,
  password: string,
  firstName: string,
  lastName: string,
  companyName: string,
  companyWebsite: string,
  phoneNumber: string,
  referralCode: string
): Promise<BaseResponse<RegisterResponseBody, BadRegisterResponseBody>> => {
  try {
    const response = await fetch(`${BASE_BACKEND_URL}/user/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        lastName,
        companyName,
        companyWebsite,
        phoneNumber,
        referralCode,
      }),
    });

    const responseObject: BaseResponse<
      RegisterResponseBody,
      BadRegisterResponseBody
    > = {
      meta: response,
      body: await response.json(),
    };
    return responseObject;
  } catch (error) {
    console.error(error);
    const failedResponse: BaseServerErrorResponse<BadRegisterResponseBody> = {
      body: {
        success: false,
        error:
          "We apologize, but there was an issue during the registration process.",
      },
    };
    return failedResponse;
  }
};
export { login, register, resetPassword };
export type {
  BadRegisterResponseBody,
  FailedResponseBody,
  LoginSuccessfulResponseBody,
  RegisterResponseBody,
  ResetPassSuccessfulResponseBody,
};
