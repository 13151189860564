/**
 * @since 2023-10-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import "./TextError.scss";

const TextError = ({ error }) => {
  return <div className="text-error">{error}</div>;
};

export default TextError;
