/**
 * @since 2023-11-16
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

const messages_RiskManagement = {
  "riskmanagement-instrument-notional":
    "Max amount in USD dollar that can be invested on each instrument",
  "riskmanagement-instrument-volatility":
    "Max yearly volatility allowed for each instrument",
  "riskmanagement-instrument-stoploss":
    "Maximum allowed loss % for a position, after which it will automatically be closed",
  "riskmanagement-instrument-takeprofit":
    "Maximum allowed profit % for a position, after which it will automatically be closed",
  "riskmanagement-instrument-trailingstoploss":
    "Maximum allowed trailing loss % for a position, after which it will automatically be closed",
  "riskmanagement-instrument-trailingtakeprofit":
    "Maximum allowed trailing profit % for a position, after which it will automatically be closed",
  "riskmanagement-instrument-graceperiod":
    "Number of periods to wait after a stop loss before re-opening an instrument position",
  "riskmanagement-instrument-onesignalperday":
    "If enabled, the strategy cannot trade each instrument more than once per day",
  "riskmanagement-sector-notionalgross":
    "Max amount in USD dollar that can be invested on each sector",
  "riskmanagement-sector-notionalnet":
    "Max amount in USD dollar that can be invested on each sector, after netting long and short positions",
  "riskmanagement-sector-volatility":
    "Max yearly volatility allowed for each sector",
  "riskmanagement-sector-stoploss":
    "Maximum allowed loss percentage for each sector. If this threshold is reached, all open positions within that sector will automatically be closed",
  "riskmanagement-sector-graceperiod":
    "Number of periods to wait after a stop loss before re-opening all the sector positions",
  "riskmanagement-strategy-notionalgross":
    "Max amount in USD dollar that can be invested on each strategy",
  "riskmanagement-strategy-notionalnet":
    "Max amount in USD dollar that can be invested on each strategy, after netting long and short positions",
  "riskmanagement-strategy-volatility":
    "Max yearly volatility allowed for each strategy",
  "riskmanagement-strategy-stoploss":
    "Maximum allowed loss percentage for each strategy. If this threshold is reached, all open positions within that strategy will automatically be closed",
  "riskmanagement-strategy-graceperiod":
    "Number of periods to wait after a stop loss before re-opening all the strategy positions",
  "riskmanagement-portfolio-notionalgross":
    "Max amount in USD dollar that can be invested",
  "riskmanagement-portfolio-notionalnet":
    "Max amount in USD dollar that can be invested, after netting long and short positions",
  "riskmanagement-portfolio-volatility":
    "Max overall yearly volatility allowed",
  "riskmanagement-portfolio-stoploss":
    "Maximum allowed overall loss percentage. If this threshold is reached, all open positions will automatically be closed",
  "riskmanagement-portfolio-graceperiod":
    "Number of periods to wait after a stop loss before re-opening all the positions",
};

const messages_ImportedBuilder = {
  "importedbuilder-settings-name":
    "Name of your strategy. It must be unique, up to 20 characters",
  "importedbuilder-settings-capital":
    "Amount of capital allocated to this strategy",
  "importedbuilder-settings-description":
    "Description of your strategy. It must be up to 400 characters",
  "importedbuilder-settings-benchmark":
    "Benchmark to compare your strategy against",
  "importedbuilder-settings-filename":
    "Load your returns file here. Currently supported: XLS, XLS, CSV, and PICKLE files",
  "importedbuilder-settings-period":
    "Choose a backtest period for your strategy. Using 10+ years of data is advised for better results, but longer periods may increase simulation time",
};

const messages_PortfolioBuilder = {
  "portfoliobuilder-settings-name":
    "Name of your portfolio. It must be unique, up to 20 characters",
  "portfoliobuilder-settings-capital":
    "Amount of capital allocated to this portfolio",
  "portfoliobuilder-settings-description":
    "Description of your portfolio. It must be up to 400 characters",
  "portfoliobuilder-settings-benchmark":
    "Benchmark to compare your portfolio against",
  "portfoliobuilder-settings-period":
    "Choose a backtest period for your portfolio. Using 10+ years of data is advised for better results, but longer periods may increase simulation time",
};

const messages_PremiumBuilder = {
  "premiumbuilder-settings-name":
    "Name of the premium portfolio. It must be unique, up to 20 characters",
  "premiumbuilder-settings-capital":
    "Amount of capital allocated to this portfolio",
  "premiumbuilder-settings-description": "Description of the premium portfolio",
  "premiumbuilder-settings-benchmark":
    "Benchmark used to compare this premium portfolio against",
  "premiumbuilder-settings-period": "Backtesting period",
};

const messages_StrategyBuilder = {
  "strategybuilder-settings-name":
    "Name of your strategy. It must be unique, up to 20 characters",
  "strategybuilder-settings-capital":
    "Amount of capital allocated to this strategy",
  "strategybuilder-settings-description":
    "Description of your strategy. It must be up to 400 characters",
  "strategybuilder-settings-frequency":
    "Trading frequency of your strategy. For fast simulations, use daily, weekly, or monthly data",
  "strategybuilder-settings-dailysignaltime":
    "Time of the day when signals are generated. It applies only to daily, weekly, or monthly strategies. It must be in the format HH:MM. EST Timezone",
  "strategybuilder-settings-strategytype":
    "Type of your strategy. If not sure, choose 'Other'. It does not affect the performance of the strategy, used only for reporting",
  "strategybuilder-settings-longshort":
    "Select if the strategy goes long or short",
  "strategybuilder-settings-benchmark":
    "Benchmark to compare your strategy against",
  "strategybuilder-settings-universeid":
    "Choose here the list of instruments that your strategy will trade",
  "strategybuilder-settings-dateperiod":
    "Choose a backtest period for your strategy. Using 10+ years of data is advised for better results, but longer periods may increase simulation time",
  "strategybuilder-settings-timeperiod":
    "Start and end time for trading your intraday strategy. EST Timezone",
};

const messages_SignalGenerator = {
  "signalgenerator-settings-name":
    "Name for your signals. It must be unique, up to 20 characters",
  "signalgenerator-settings-portfolio":
    "Portfolio to use for generating the signals",
  "signalgenerator-settings-isactive":
    "Whether the live signal generator is active or not",
  "signalgenerator-settings-description":
    "Description for your signals. It must be up to 400 characters",
  "signalgenerator-settings-emails-enabled":
    "Receive emails when there is a new trade",
  "signalgenerator-settings-text-enabled":
    "Receive text messages when there is a new trade",
  "signalgenerator-settings-slack-enabled":
    "Receive slack messages when there is a new trade",
  "signalgenerator-settings-api-enabled":
    "Receive real-time notifications via API",
  "signalgenerator-settings-email-addresses":
    "Comma separated list of email addresses",
  "signalgenerator-settings-text-phonenumber":
    "Phone number used to receive text messages",
  "signalgenerator-settings-slack-webhook":
    "Channel webhook used to receive slack messages",
  "signalgenerator-settings-api-key":
    "Enter this API Key to authenticate your software and establish a secure connection to our servers",
  "signalgenerator-settings-startofday":
    "Receive a notification containing your initial positions",
  "signalgenerator-settings-trades":
    "Receive a notification for each trade generated by your portfolio",
  "signalgenerator-settings-endofday":
    "Receive a notification at the end of the day containing your final position and the list of trades generated during the day",
  "signalgenerator-settings-riskmanagement":
    "Receive a notification for each risk management trade. These trades are only internal, and do not need to be sent to your broker",
};

const messages_Live_Performance = {
  "live-performance-position":
    "Amount of the financial instrument held, expressed in units or contracts.",
  "live-performance-price":
    "Current market price of the financial instrument in USD $",
  "live-performance-profit&loss":
    "Current profit or loss, expressed in USD dollars. Positive values represent profits, while negative values denote losses.",
  "live-performance-grossnotional":
    "Total value of all open positions without considering offsetting positions",
  "live-performance-netnotional":
    "Total value of all open positions after accounting for offsetting positions",
  "live-performance-volatility":
    "Expected daily change, either positive or negative",
  "live-performance-valueatrisk":
    "Potential loss in value of the portfolio, within a 95% confidence interval",
};

const messages_Live_Trades = {
  "live-trades-datetime": "Date and time when the trade was executed",
  "live-trades-strategy": "Trading strategy used for the trade",
  "live-trades-instrument": "Financial instrument traded",
  "live-trades-ticker": "Bloomberg Ticker of the financial instrument traded",
  "live-trades-traded": "Quantity of the financial instrument traded",
  "live-trades-price": "Price at which the financial instrument was traded",
  "live-trades-newposition":
    "New position in the financial instrument for the given strategy after the trade was executed",
};

const messages_Trades = {
  "trades-datetime": "Date and time when the trade was executed",
  "trades-category": "Asset class of the instrument traded",
  "trades-instrument": "Financial instrument traded",
  "trades-ticker": "Bloomberg Ticker of the financial instrument traded",
  "trades-traded": "Quantity of the financial instrument traded",
  "trades-price": "Price at which the financial instrument was traded",
  "trades-newposition":
    "New position in the financial instrument for the given strategy after the trade was executed",
};

const messages_PerformanceTable = {
  "performance-table-name": "Name of the strategy",
  "performance-table-pnl": "Amount in $ generated by the strategy",
  "performance-table-traded":
    "Percentage of the time in which the strategy is trading.<br/><br/>0%: the strategy never trades.<br/>100%: the strategy trades every day",
  "performance-table-win":
    "Percentage of the time in which the strategy was profitable",
  "performance-table-averagereturn": "Average daily return of the strategy",
  "performance-table-minreturn": "Minimum daily return of the strategy",
  "performance-table-maxreturn": "Maximum daily return of the strategy",
  "performance-table-minposition":
    "Minimum allocation percentage of the strategy",
  "performance-table-maxposition":
    "Maximum allocation percentage of the strategy",
  "performance-table-return": "Annualized return of the strategy",
  "performance-table-commission": "Annualized commission of the strategy",
  "performance-table-slippage": "Annualized slippage of the strategy",
  "performance-table-volatility": "Annualized volatility of the strategy",
  "performance-table-mdd": "Maximum drawdown of the strategy",
  "performance-table-retmdd":
    "Annualized return divided by the maximum drawdown",
  "performance-table-retcst":
    "Annualized return divided by the annualized cost",
  "performance-table-sharpe": "Sharpe ratio of the strategy",
};

const messages_Reports = {
  "reports-benchmark-correlation":
    "How similarly the returns of your strategy and the benchmark move",
  "reports-benchmark-alpha":
    "The extra return your strategy generates over the benchmark, adjusted for risk",
  "reports-benchmark-beta":
    "The sensitivity of your strategy's returns to the benchmark's returns",
  "reports-factors-overallexposure":
    "Total amount of capital at risk in your strategy relative to the factor. A comprehensive model that includes all relevant factors has been created and this shows the exposure to each factor. This helps in understanding the overall risk profile and potential impact of different factors on your strategy’s returns.",
  "reports-factors-individualexposure":
    "Total amount of capital at risk in your strategy relative to the factor. A separate model for each factor has been created and this shows the exposure to the factor. This helps in isolating the impact of each factor and understanding how much each one contributes to the overall risk and performance of the strategy",
  "reports-factors-individualcorrelation":
    "The relationship between the returns of your strategy and each specific factor individually",
  "reports-optimization-portfolioweights":
    "Current allocation defined in the settings",
  "reports-optimization-equalweights":
    "It allocates an equal proportion of the total portfolio value to each strategy, regardless of the strategy's risk or expected return",
  "reports-optimization-minvariance":
    "It aims to construct a portfolio with the lowest possible volatility. It optimizes the asset weights to minimize the overall portfolio risk, focusing solely on reducing variance without considering the expected returns.",
  "reports-optimization-riskparity":
    "It allocates portfolio weights such that each asset contributes equally to the overall portfolio risk. This approach balances the risk across all strategies, leading to diversified risk exposure and often results in a more stable portfolio",
  "reports-optimization-maxsharperatio":
    "It seeks to maximize the Sharpe Ratio, which is the ratio of the portfolio's excess return over the risk-free rate to its standard deviation. It aims to achieve the highest return per unit of risk, optimizing the trade-off between risk and return.",
};

const messages = {
  en: {
    ...messages_RiskManagement,
    ...messages_StrategyBuilder,
    ...messages_ImportedBuilder,
    ...messages_PortfolioBuilder,
    ...messages_PremiumBuilder,
    ...messages_SignalGenerator,
    ...messages_Live_Performance,
    ...messages_Live_Trades,
    ...messages_Trades,
    ...messages_PerformanceTable,
    ...messages_Reports,
  },
};

export default messages;
