/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ChartReturns from "components/Shared/ChartReturns";

const ItemChartSimStatsCollector = ({ itemData }) => {
  return <ChartReturns isLoading={false} chartData={itemData} />;
};

export default ItemChartSimStatsCollector;
