/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Report from "./Section";

export default Report;
