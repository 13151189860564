/**
 * @since 2023-11-25
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Spread from "./Spread";

export default Spread;
