/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Dashboard from "components/Dashboard";
import Login from "components/Login";

import ResetPassword from "components/ResetPassword";
import ErrorPage from "components/Shared/UI/ErrorPage";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import messages from "../../messages";
import "./App.scss";

const App = () => {
  return (
    <>
      <IntlProvider locale={"en"} messages={messages["en"]}>
        <div className="app-container">
          <BrowserRouter>
            <Routes>
              <Route path="/*" element={<Dashboard />} />
              <Route
                path="/login"
                element={<Login showRegistration={false} />}
              />
              <Route
                path="/register"
                element={<Login showRegistration={true} />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/404" element={<ErrorPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </BrowserRouter>
        </div>
      </IntlProvider>
    </>
  );
};

export default App;
