/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarActionsAnalysis from "components/Shared/BarActionsAnalysis";
import ButtonBlue from "components/Shared/ButtonBlue";
import Report from "components/Shared/Report";
import TextCopyright from "components/Shared/TextCopyright";
import TextError from "components/Shared/TextError";
import TextWait from "components/Shared/TextWait";
import {
  createStrategyAnalysis,
  getStatusStrategyAnalysis,
  loadStrategyAnalysis,
  waitForStrategyAnalysis,
} from "dataHandling/strategy";
import { useEffect, useState } from "react";

const PanelAnalysis = ({ email, strategyId, strategyName, onScoresClick }) => {
  //region States

  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState("");
  const [analysisData, setAnalysisData] = useState({});

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [strategyId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    let newStatus = await getStatusStrategyAnalysis(email, strategyId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForStrategyAnalysis(email, strategyId);
    }
    if (newStatus === "OK") {
      const newAnalysisData = await loadStrategyAnalysis(email, strategyId);
      setAnalysisData(newAnalysisData);
    }
    setStatus(newStatus);
  };

  const handleCreateAnalysis = async () => {
    setStatus("WAITING");
    await createStrategyAnalysis(email, strategyId);
    fetchData();
  };

  //endregion

  return (
    <>
      {status === "WAITING" && <TextWait />}
      {status === "ERROR" && (
        <>
          <TextError error="Something went wrong, we are working on it. Please try again later." />
          <ButtonBlue
            label="CREATE ANALYSIS"
            alignment="left"
            onClick={handleCreateAnalysis}
          />
        </>
      )}
      {status === "NEW" && (
        <ButtonBlue
          label="CREATE ANALYSIS"
          alignment="left"
          onClick={handleCreateAnalysis}
        />
      )}
      {status === "OK" && (
        <>
          <Report
            email={email}
            moduleType={"STRATEGY"}
            moduleId={strategyId}
            reportData={analysisData}
          />
          <br />
          <br />
          <BarActionsAnalysis
            email={email}
            moduleType={"STRATEGY"}
            moduleId={strategyId}
            moduleName={strategyName}
            showTrades={true}
          />
          <br />
          <ButtonBlue label="SEE SCORES" onClick={onScoresClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelAnalysis;
