/**
 * @since 2024-06-01
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableWithPagination from "components/Shared/TableWithPagination";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { parseNumber } from "utils/parsing";

const ItemTableFactorExposureIndividual = ({ itemData }) => {
  const intl = useIntl();
  const [dataTable, setDataTable] = useState(null);

  const columns = [
    {
      title: "Factor",
      dataIndex: "index",
      key: "index",
      sorter: (a, b) => a.index.localeCompare(b.index),
      showSorterTooltip: false,
    },
    {
      title: "Exposure",
      dataIndex: "exposure",
      key: "exposure",
      align: "right",
      tooltip: intl.formatMessage({
        id: "reports-factors-individualexposure",
      }),
      sorter: (a, b) => parseNumber(a.exposure) - parseNumber(b.exposure),
      showSorterTooltip: false,
    },
    {
      title: "Correlation",
      dataIndex: "correlation",
      key: "correlation",
      align: "right",
      tooltip: intl.formatMessage({
        id: "reports-factors-individualcorrelation",
      }),
      sorter: (a, b) => parseNumber(a.correlation) - parseNumber(b.correlation),
      showSorterTooltip: false,
    },
  ];

  useEffect(() => {
    if (itemData === null) return;
    setDataTable(convertToAntTableData(itemData));
  }, [itemData]);

  function convertToAntTableData(data) {
    return data.Index.map((indexName, rowIndex) => {
      let tableItem = {
        key: rowIndex,
        index: indexName,
        exposure: parseNumber(itemData.Values[rowIndex][0]),
        correlation: parseNumber(itemData.Values[rowIndex][1]),
      };
      return tableItem;
    });
  }

  return (
    <div style={{ width: "50%", maxWidth: "800px" }}>
      <TableWithPagination dataTable={dataTable} columns={columns} />
    </div>
  );
};
export default ItemTableFactorExposureIndividual;
