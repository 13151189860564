/**
 * @since 2024-05-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import PanelSubscription from "./PanelSubscription";

export default PanelSubscription;
