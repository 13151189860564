/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

const firebaseConfig = {
  apiKey: "AIzaSyBrKghMhTvwUJQaWQL_f4zu5J56yCXV8jA",
  authDomain: "algoapps.firebaseapp.com",
  projectId: "algoapps",
  storageBucket: "algoapps.appspot.com",
  messagingSenderId: "879547576322",
  appId: "1:879547576322:web:2601663d55f1f1d82ff71b",
  measurementId: "G-34332GGR6T",
};
export default firebaseConfig;
