/**
 * @since 2023-11-18
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ChartReturnsAndDrawdowns from "components/Shared/ChartReturnsAndDrawdowns";

const ItemChartSimStatsCollectorWithDrawdowns = ({ itemData }) => {
  return <ChartReturnsAndDrawdowns isLoading={false} chartData={itemData} />;
};

export default ItemChartSimStatsCollectorWithDrawdowns;
