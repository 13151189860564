/**
 * @since 2023-12-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ButtonBlue from "components/Shared/ButtonBlue";
import Report from "components/Shared/Report";
import TextCopyright from "components/Shared/TextCopyright";
import TextMessage from "components/Shared/TextMessage";
import TextWait from "components/Shared/TextWait";
import {
  getLiveStatsDates,
  loadLiveStats,
  loadSignalSettings,
} from "dataHandling/signal";
import { useEffect, useState } from "react";
import BarActionsAnalysis from "./BarActionsAnalysis";
import "./PanelLiveStats.scss";

// Add missing import

const PanelSignals = ({ email, signalId }) => {
  //region States
  const [isLoading, setIsLoading] = useState(true);
  const [liveStatsData, setLiveStatsData] = useState([]);
  const [listDates, setListDates] = useState([]);
  const [date, setDate] = useState(null);
  const [signalSettings, setSignalSettings] = useState(null);

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchListDates();
    fetchSettings();
  }, [signalId]);

  //endregion

  //region Methods

  const fetchListDates = async () => {
    const newListDates = await getLiveStatsDates(email, signalId);
    const formattedDates = newListDates.map(
      (date) =>
        `${date.substring(0, 4)}-${date.substring(4, 6)}-${date.substring(
          6,
          8
        )}`
    );
    setListDates(formattedDates);
    if (newListDates.length > 0) {
      const newDate = formattedDates[formattedDates.length - 1];
      setDate(newDate);
      await fetchDataByDate(newDate);
    }
    setIsLoading(false);
  };

  const fetchSettings = async () => {
    const newSignalSettings = await loadSignalSettings(email, signalId);
    setSignalSettings(newSignalSettings);
  };

  const fetchDataByDate = async (date) => {
    if (date === null) return;
    setIsLoading(true);
    const newLiveStatsData = await loadLiveStats(email, signalId, date);
    setLiveStatsData(newLiveStatsData);
    setIsLoading(false);
  };

  const handleDateSelected = async (event, newDate) => {
    if (newDate) {
      setDate(newDate);
      await fetchDataByDate(newDate);
    } else {
      // Refresh current
      await fetchDataByDate(date);
    }
  };

  const handleRefresh = async () => {
    fetchDataByDate(date);
  };

  const showMessage = () => {
    if (signalSettings === null) return <TextWait />;
    if (signalSettings.IsActive) {
      return (
        <TextMessage message="Your strategy is active. Statistics will be generated during trading hours." />
      );
    }
    return (
      <TextMessage message="Strategy not active. Enable in in the settings if you would like to receive trading signals." />
    );
  };

  //endregion

  return (
    <div className="panelLiveStats">
      {listDates.length === 0 ? (
        isLoading ? (
          <TextWait />
        ) : (
          showMessage()
        )
      ) : (
        <>
          <ToggleButtonGroup
            className="toggleDates"
            value={date}
            exclusive
            onChange={handleDateSelected}
            aria-label="view"
            style={{
              marginRight: "0px",
              marginTop: "40px",
            }}
          >
            {listDates.map((dt, index) => (
              <ToggleButton key={index} value={dt} aria-label={dt}>
                {dt}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          {isLoading ? (
            <TextWait />
          ) : liveStatsData ? (
            <>
              <Report
                email={email}
                moduleType={"SIGNAL"}
                moduleId={signalId}
                reportData={liveStatsData}
              />
              <BarActionsAnalysis
                email={email}
                signalId={signalId}
                signalName={signalSettings.SignalName}
                date={date}
              />
            </>
          ) : (
            showMessage()
          )}
          <ButtonBlue label="REFRESH" height="40px" onClick={handleRefresh} />

          <TextCopyright />
        </>
      )}
    </div>
  );
};
export default PanelSignals;
