/**
 * @since 2023-11-18
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ChartReturnsAndDrawdowns from "./ChartReturnsAndDrawdowns";

export default ChartReturnsAndDrawdowns;
