/**
 * @since 2023-04-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import SectionScoresStop from "./SectionScoresStop";

export default SectionScoresStop;
