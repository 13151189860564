/**
 * @since 2024-05-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableWithPagination from "./TableWithPagination";

export default TableWithPagination;
