/**
 * @since 2024-05-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { getSubscriptionLinks } from "dataHandling/general.js";
import { useEffect, useState } from "react";

const StripePricingTable = ({ email }) => {
  const [connectionData, setConnectionData] = useState(null);

  useEffect(() => {
    // Fetch subscription links
    const fetchData = async () => {
      const data = await getSubscriptionLinks(email);
      setConnectionData(data);
    };
    fetchData();
  }, [email]);

  useEffect(() => {
    // Add the Stripe script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!connectionData) {
    return <div>Loading...</div>; // or any other loading indicator
  }

  return (
    <stripe-pricing-table
      pricing-table-id={connectionData["pricing-table-id"]}
      publishable-key={connectionData["publishable-key"]}
      customer-email={email}
    />
  );
};

export default StripePricingTable;
