/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ChartReturnsAndDrawdowns from "components/Shared/ChartReturnsAndDrawdowns";

const ItemChartSimStats = ({ itemData }) => {
  return <ChartReturnsAndDrawdowns isLoading={false} chartData={itemData} />;
};

export default ItemChartSimStats;
