/**
 * @since 2023-05-12
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import DisplayBox from "./DisplayBox";

export default DisplayBox;
