/**
 * @since 2023-04-15
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import PanelHeader from "components/Shared/PanelHeader";
import { FiRss } from "react-icons/fi";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import PanelLiveStats from "./PanelLiveStats";
import PanelSettings from "./PanelSettings";
import ToggleButtonGroupSignals from "./ToggleButtonGroupSignals";

const SignalGenerator = ({
  email,
  menuItem,
  listSignalNames,
  listLockedIds,
  configuration,
  dictPortfolios,
  isNavBarVisible,
  onSignalNameChange,
  onSettingsRefresh,
}) => {
  const navigate = useNavigate();

  const goToLiveView = () => {
    navigate("live", { relative: true });
  };

  return (
    <>
      {menuItem && (
        <>
          <PanelHeader
            menuItem={menuItem}
            icon={<FiRss style={{ fontSize: "30px" }} />}
            toggleButtonGroup={
              <ToggleButtonGroupSignals selectedValue={menuItem.ItemView} />
            }
            isNavBarVisible={isNavBarVisible}
          />
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path={"settings"}
                element={
                  <PanelSettings
                    email={email}
                    signalId={menuItem.ItemId}
                    signalName={menuItem.ItemName}
                    listSignalNames={listSignalNames}
                    nActiveSignals={
                      listLockedIds.filter((id) => id.startsWith("SGN-")).length
                    }
                    configuration={configuration}
                    dictPortfolios={dictPortfolios}
                    onSignalNameChange={onSignalNameChange}
                    onSettingsRefresh={onSettingsRefresh}
                    onLiveClick={goToLiveView}
                  />
                }
              />
              <Route
                path={"live"}
                element={
                  <PanelLiveStats
                    email={email}
                    signalId={menuItem.ItemId}
                    signalName={menuItem.ItemName}
                  />
                }
              />
              <Route path="*" element={<Navigate to="settings" />} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};
export default SignalGenerator;
