/**
 * @since 2023-07-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableSettings from "./TableSettings";

export default TableSettings;
