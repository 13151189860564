/**
 * @since 2023-08-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import NotificationCheckboxGroup from "components/Shared/NotificationCheckboxGroup";
import Tooltip from "components/Shared/Tooltip";
import { notifySlackTest } from "dataHandling/signal";
import { useState } from "react";
import { useIntl } from "react-intl";
import "./TableSettings_Slack.scss";

const TableSettings_Slack = ({
  slackWebhookUrl,
  sendStartOfDayNotifications,
  sendTradesNotifications,
  sendEndOfDayNotifications,
  sendRiskManagementNotifications,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();

  const [slackWebhookUrlEdited, setSlackWebhookUrlEdited] =
    useState(slackWebhookUrl);
  const [slackTestMessage, setSlackTestMessage] = useState(null);
  const [
    sendStartOfDayNotificationsEdited,
    setSendStartOfDayNotificationsEdited,
  ] = useState(sendStartOfDayNotifications);
  const [sendTradesNotificationsEdited, setSendTradesNotificationsEdited] =
    useState(sendTradesNotifications);
  const [sendEndOfDayNotificationsEdited, setSendEndOfDayNotificationsEdited] =
    useState(sendEndOfDayNotifications);
  const [
    sendRiskManagementNotificationsEdited,
    setSendRiskManagementNotificationsEdited,
  ] = useState(sendRiskManagementNotifications);

  const cancel = () => {
    onIsEditUpdate(false);
    setSlackWebhookUrlEdited(slackWebhookUrl);
  };

  const handleInputChange_Webhook = (settingName, settingNewValue) => {
    setSlackWebhookUrlEdited(settingNewValue);
  };

  const handleInputChange_EndOfDay = (settingNewValue) => {
    setSendEndOfDayNotificationsEdited(settingNewValue);
  };

  const handleInputChange_StartOfDay = (settingNewValue) => {
    setSendStartOfDayNotificationsEdited(settingNewValue);
  };

  const handleInputChange_RiskManagement = (settingNewValue) => {
    setSendRiskManagementNotificationsEdited(settingNewValue);
  };

  const handleInputChange_Trades = (settingNewValue) => {
    setSendTradesNotificationsEdited(settingNewValue);
  };

  const save = () => {
    onSettingsChange(
      slackWebhookUrlEdited,
      sendStartOfDayNotificationsEdited,
      sendTradesNotificationsEdited,
      sendEndOfDayNotificationsEdited,
      sendRiskManagementNotificationsEdited
    );
    onIsEditUpdate(false);
  };

  const sendSlackTest = async () => {
    const isOk = await notifySlackTest(slackWebhookUrlEdited);
    if (isOk) {
      setSlackTestMessage({
        message: "Test message sent successfully",
        isError: false,
      });
    } else {
      setSlackTestMessage({
        message: "Test failed. Please check your webhook URL.",
        isError: true,
      });
    }
  };

  return (
    <div className="table-settings-slack-container">
      {isEdit && (
        <div className="setting-description">
          This will allow you to receive new trading signals directly in slack.
          To configure your Slack Webhook, you can follow{" "}
          <a
            href="https://api.slack.com/messaging/webhooks"
            target="_blank"
            style={{ color: "orange" }}
          >
            this guide
          </a>
          .
          <br />
          <br />
          An example of a webhook URL is{" "}
          <span style={{ color: "lightblue" }}>
            https://hooks.slack.com/services/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
          </span>
          .
        </div>
      )}
      <div className="settings-fields">
        <Tooltip
          tooltipText={intl.formatMessage({
            id: "signalgenerator-settings-slack-webhook",
          })}
          size="small"
        >
          <div
            className="setting-name"
            style={{ marginTop: isEdit ? "12px" : "0px" }}
          >
            {"WebHook"}
          </div>
        </Tooltip>
        <div className="setting-input-value">
          {isEdit ? (
            <div className="edit-container">
              <InputBox
                name={"SlackWebhook"}
                value={slackWebhookUrlEdited}
                nRows={1}
                maxLength={200}
                onUpdate={handleInputChange_Webhook}
              />
            </div>
          ) : slackWebhookUrlEdited ? (
            slackWebhookUrlEdited
          ) : (
            "Not set"
          )}
        </div>
      </div>
      <NotificationCheckboxGroup
        sendStartOfDayNotifications={sendStartOfDayNotificationsEdited}
        sendTradesNotifications={sendTradesNotificationsEdited}
        sendEndOfDayNotifications={sendEndOfDayNotificationsEdited}
        sendRiskManagementNotifications={sendRiskManagementNotificationsEdited}
        onStartOfDayNotificationsChange={handleInputChange_StartOfDay}
        onTradesNotificationsChange={handleInputChange_Trades}
        onEndOfDayNotificationsChange={handleInputChange_EndOfDay}
        onRiskManagementNotificationsChange={handleInputChange_RiskManagement}
        isEdit={isEdit}
      />
      {isEdit && (
        <div className="setting-description">
          <button className="test-webhook-button" onClick={sendSlackTest}>
            Test Webhook
          </button>
          {slackTestMessage && (
            <span
              style={{
                marginLeft: "10px",
                color: slackTestMessage.isError ? "red" : "green",
              }}
            >
              {slackTestMessage.message}
            </span>
          )}
        </div>
      )}
      {isEdit && (
        <div className="button-container">
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={false}
          />
        </div>
      )}
    </div>
  );
};
export default TableSettings_Slack;
