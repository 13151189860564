/**
 * @since 2024-05-03
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { Modal } from "antd";
import { useEffect, useState } from "react";
import "./ModalSettings.scss";
import PanelProfile from "./PanelProfile";
import PanelSubscription from "./PanelSubscription";

const ModalSettings = ({
  isOpen,
  email,
  menuItems,
  onCancel,
  onSettingsChanged,
}) => {
  const categories = ["PROFILE", "SUBSCRIPTION"];
  const [category, setCategory] = useState("PROFILE");

  //region UseEffects

  useEffect(() => {
    setCategory("PROFILE");
  }, [isOpen]);

  //endregion

  const handleCategoryChangle = (event, newCategory) => {
    if (!newCategory) return;
    setCategory(newCategory);
  };

  const showTab = () => {
    switch (category) {
      case "PROFILE":
        return (
          <PanelProfile
            email={email}
            menuItems={menuItems}
            onCancel={onCancel}
            onSettingsChanged={onSettingsChanged}
          />
        );
      case "SUBSCRIPTION":
        return (
          <PanelSubscription
            email={email}
            menuItems={menuItems}
            onCancel={onCancel}
            onSubscriptionChanged={onSettingsChanged}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={isOpen}
      title={"SETTINGS"}
      footer={null}
      onCancel={onCancel}
      className="modal-settings"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          marginTop: "40px",
        }}
      >
        <div>
          <ToggleButtonGroup
            className="button-group-category"
            value={category}
            exclusive
            onChange={handleCategoryChangle}
            aria-label="view"
          >
            {categories.map((cat, index) => (
              <ToggleButton
                key={cat}
                value={cat}
                aria-label={cat}
                style={{
                  width: "250px",
                  color: category === cat ? "#78bcfb" : "white",
                  borderColor: category === cat ? "#78bcfb" : "initial",
                  borderWidth: category === cat ? "1px" : "initial",
                  borderStyle: category === cat ? "solid" : "initial",
                  marginRight: index !== categories.length - 1 ? "1px" : "0px",
                }}
              >
                {`${cat}`}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>
        {showTab()}
      </div>
    </Modal>
  );
};

export default ModalSettings;
