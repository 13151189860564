/**
 * @since 2023-07-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import SectionScoresError from "components/Shared/SectionScoresError";
import TableScoresWithFilters from "components/Shared/TableScoresWithFilters";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import {
  createImportedAnalysis,
  getStatusImportedAnalysis,
  loadImportedScores,
  waitForImportedAnalysis,
} from "dataHandling/imported";
import { useEffect, useState } from "react";

const PanelScores = ({ email, importedId, onReportsClick }) => {
  //region States

  const [status, setStatus] = useState("");
  const [scoresTable, setScoresTable] = useState(null);

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [importedId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    let newStatus = await getStatusImportedAnalysis(email, importedId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForImportedAnalysis(email, importedId);
    }
    if (newStatus === "OK") {
      const newScoresTable = await loadImportedScores(email, importedId);
      setScoresTable(newScoresTable);
    }
    setStatus(newStatus);
  };

  const handleCreateAnalysis = async () => {
    setStatus("WAITING");
    await createImportedAnalysis(email, importedId);
    fetchData();
  };

  //endregion

  return (
    <>
      {status === "NEW" && (
        <ButtonBlue
          label="CREATE SCORES"
          alignment="left"
          onClick={handleCreateAnalysis}
        />
      )}
      {status === "WAITING" && <TextWait />}
      {status === "ERROR" && (
        <SectionScoresError onClick={handleCreateAnalysis} />
      )}
      {status === "OK" && (
        <>
          <TableScoresWithFilters
            scoresTable={scoresTable}
            showRecommendations={true}
          />
          <br />
          <br />
          <ButtonBlue label="SEE REPORTS" onClick={onReportsClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};

export default PanelScores;
