/**
 * @since 2023-10-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import NotificationCheckboxGroup from "components/Shared/NotificationCheckboxGroup";
import Tooltip from "components/Shared/Tooltip";
import { notifyTextMessageTest } from "dataHandling/signal";
import { useState } from "react";
import { useIntl } from "react-intl";
import { validatePhoneNumber } from "utils/validation";
import "./TableSettings_TextMessage.scss";

const TableSettings_TextMessage = ({
  email,
  signalId,
  textMessageNumber,
  sendStartOfDayNotifications,
  sendTradesNotifications,
  sendEndOfDayNotifications,
  sendRiskManagementNotifications,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();

  //region States

  const [textMessageNumberEdited, setTextMessageNumberEdited] =
    useState(textMessageNumber);
  const [textMessageTestMessage, setTextMessageTestMessage] = useState(null);
  const [
    sendStartOfDayNotificationsEdited,
    setSendStartOfDayNotificationsEdited,
  ] = useState(sendStartOfDayNotifications);
  const [sendTradesNotificationsEdited, setSendTradesNotificationsEdited] =
    useState(sendTradesNotifications);
  const [sendEndOfDayNotificationsEdited, setSendEndOfDayNotificationsEdited] =
    useState(sendEndOfDayNotifications);
  const [
    sendRiskManagementNotificationsEdited,
    setSendRiskManagementNotificationsEdited,
  ] = useState(sendRiskManagementNotifications);
  const [errorMessage, setErrorMessage] = useState("");

  //endregion

  //region Methods

  const cancel = () => {
    onIsEditUpdate(false);
    setTextMessageNumberEdited(textMessageNumber);
  };

  const save = () => {
    onSettingsChange(
      textMessageNumberEdited,
      sendStartOfDayNotificationsEdited,
      sendTradesNotificationsEdited,
      sendEndOfDayNotificationsEdited,
      sendRiskManagementNotificationsEdited
    );
    onIsEditUpdate(false);
  };

  const sendTextMessageTest = async () => {
    if (textMessageNumberEdited.length === 0) {
      setTextMessageTestMessage({
        message: "Phone number not inserted.",
        isError: true,
      });
      return;
    }
    const isOk = await notifyTextMessageTest(
      email,
      signalId,
      textMessageNumberEdited
    );
    if (isOk) {
      setTextMessageTestMessage({
        message: "Test message sent successfully",
        isError: false,
      });
    } else {
      setTextMessageTestMessage({
        message: "Test failed. Please check your TextMessage number.",
        isError: true,
      });
    }
  };

  const handleInputChange_PhoneNumber = (settingName, settingNewValue) => {
    setTextMessageNumberEdited(settingNewValue);
    validate(settingNewValue);
    setTextMessageTestMessage({
      message: "",
      isError: false,
    });
  };

  const handleInputChange_EndOfDay = (settingNewValue) => {
    setSendEndOfDayNotificationsEdited(settingNewValue);
  };

  const handleInputChange_StartOfDay = (settingNewValue) => {
    setSendStartOfDayNotificationsEdited(settingNewValue);
  };

  const handleInputChange_RiskManagement = (settingNewValue) => {
    setSendRiskManagementNotificationsEdited(settingNewValue);
  };

  const handleInputChange_Trades = (settingNewValue) => {
    setSendTradesNotificationsEdited(settingNewValue);
  };

  const validate = (phoneNumber) => {
    if (validatePhoneNumber(phoneNumber)) {
      setErrorMessage("");
    } else {
      setErrorMessage("The phone number `" + phoneNumber + "` is not valid.");
    }
  };

  //endregion

  return (
    <div className="table-settings-textmessage-container">
      {isEdit && (
        <div className="setting-description">
          This will allow you to receive new trading signals directly in
          TextMessage.
        </div>
      )}
      <div className="settings-fields">
        <Tooltip
          tooltipText={intl.formatMessage({
            id: "signalgenerator-settings-text-phonenumber",
          })}
          size="small"
        >
          <div
            className="setting-name"
            style={{ marginTop: isEdit ? "12px" : "0px" }}
          >
            {"Phone Number"}
          </div>
        </Tooltip>
        <div className="setting-input-value">
          {isEdit ? (
            <div className="edit-container">
              <InputBox
                name={"Phone Number"}
                value={textMessageNumberEdited}
                nRows={1}
                maxLength={200}
                onUpdate={handleInputChange_PhoneNumber}
              />
            </div>
          ) : textMessageNumberEdited ? (
            textMessageNumberEdited
          ) : (
            "Not set"
          )}
        </div>
      </div>
      {isEdit && errorMessage.length > 0 && (
        <div className="errors-container">{errorMessage}</div>
      )}
      {isEdit && (
        <div className="setting-description">
          <button
            className="test-textmessage-button"
            onClick={sendTextMessageTest}
          >
            Send test message
          </button>
          {textMessageTestMessage && (
            <span
              style={{
                marginLeft: "10px",
                color: textMessageTestMessage.isError ? "red" : "green",
              }}
            >
              {textMessageTestMessage.message}
            </span>
          )}
        </div>
      )}
      <NotificationCheckboxGroup
        sendStartOfDayNotifications={sendStartOfDayNotificationsEdited}
        sendTradesNotifications={sendTradesNotificationsEdited}
        sendEndOfDayNotifications={sendEndOfDayNotificationsEdited}
        sendRiskManagementNotifications={sendRiskManagementNotificationsEdited}
        onStartOfDayNotificationsChange={handleInputChange_StartOfDay}
        onTradesNotificationsChange={handleInputChange_Trades}
        onEndOfDayNotificationsChange={handleInputChange_EndOfDay}
        onRiskManagementNotificationsChange={handleInputChange_RiskManagement}
        isEdit={isEdit}
      />
      {isEdit && (
        <div className="button-container">
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={errorMessage.length > 0}
          />
        </div>
      )}
    </div>
  );
};
export default TableSettings_TextMessage;
