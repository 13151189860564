/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import PanelReports from "./PanelReports";

export default PanelReports;
