/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import EditableSection from "components/Shared/EditableSection";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import { loadPremiumSettings } from "dataHandling/premium";
import { useEffect, useState } from "react";
import PreviewChart from "./PreviewChart";
import TableSettings from "./TableSettings";

const PanelSettings = ({
  email,
  portfolioId,
  configuration,
  onAnalysisClick,
}) => {
  const [portfolioSettings, setPortfolioSettings] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const newPortfolioSettings = await loadPremiumSettings(
        email,
        portfolioId
      );
      setPortfolioSettings(newPortfolioSettings);
      setIsLoading(false);
    })();
  }, [portfolioId, email]);

  return (
    <>
      {!portfolioId || isLoading ? (
        <TextWait />
      ) : (
        <>
          <EditableSection
            title={"SETTINGS"}
            isOpened={true}
            className="editable-section"
            canEdit={false}
            isEdit={false}
          >
            <TableSettings
              email={email}
              settings={portfolioSettings}
              configuration={configuration}
            />
          </EditableSection>
          <EditableSection
            title={"PREVIEW"}
            isOpened={true}
            className="editable-section"
            canEdit={false}
            isEdit={false}
          >
            <div>
              <PreviewChart
                email={email}
                portfolioSettings={portfolioSettings}
              />
            </div>
          </EditableSection>
          <br />
          <br />
          <ButtonBlue label="SEE ANALYSIS" onClick={onAnalysisClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelSettings;
