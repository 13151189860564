/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Section from "components/Shared/Report/Section";

const Report = ({
  email,
  moduleType,
  moduleId,
  reportData,
  showRecommendations = true,
}) => {
  return (
    <>
      {reportData && (
        <div>
          <h2 style={{ color: "white", marginTop: "50px", cursor: "default" }}>
            {reportData.Name}
          </h2>
          <p
            style={{
              color: "white",
              marginTop: "20px",
              marginBottom: "20px",
              fontSize: "14px",
              cursor: "default",
            }}
          >
            {reportData.Description}
          </p>
          {reportData.Sections &&
            reportData.Sections.map((sectionData, index) => (
              <Section
                key={index}
                email={email}
                moduleType={moduleType}
                moduleId={moduleId}
                sectionData={sectionData}
                showRecommendations={showRecommendations}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default Report;
