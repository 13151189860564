/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { Select } from "antd";
import { useState } from "react";
import { IoMdClose } from "react-icons/io";
import "./ParameterSelector.scss";

const ParameterSelector = ({
  parameterIndex,
  parameterType,
  values,
  value,
  allValues,
  minValue,
  maxValue,
  onValueChange,
  onValidation,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const minValueParsed = parseFloat(minValue);
  const maxValueParsed = parseFloat(maxValue);
  const defaultOptions = allValues;

  const defaultOptionsDict = defaultOptions.map((item) => ({
    index: item,
    label: item,
    value: item,
  }));

  const cleanUpValues = (newValues) => {
    switch (parameterType) {
      case "Float":
        // Convert to floats
        let floatValues = newValues.map((str) => parseFloat(str));
        // Sort and remove duplicates
        floatValues = [...new Set(floatValues.sort((a, b) => a - b))];
        return floatValues.map((x) => String(x));
      case "Integer":
        // Convert to integer
        let intValues = newValues.map((str) => parseInt(str));
        // Sort and remove duplicates
        intValues = [...new Set(intValues.sort((a, b) => a - b))];
        return intValues.map((x) => String(x));
      case "Choice":
        // Trim values
        let stringValues = newValues.map((str) => str.trim());
        // Sort and remove duplicates
        stringValues = [...new Set(stringValues.sort())];
        return stringValues;
      default:
        console.log(`ParameterType ${parameterType} not recognized.`);
        return false;
    }
  };

  const handleChange = (newValues) => {
    if (newValues.length <= 0) return;
    if (!newValues.every(validate)) {
      return;
    }
    const newValuesCleanedUp = cleanUpValues(newValues);
    onValueChange(parameterIndex, newValuesCleanedUp, value);
  };

  const handleBlur = () => {
    const validationResult = values.every(validate);
    if (onValidation) {
      onValidation(validationResult);
    }
  };

  const handleKeyPress = (event) => {
    validate(event.target.value + event.key);
  };

  const handleTagClick = (e, value) => {
    e.stopPropagation();
    const newValue = String(value);
    onValueChange(parameterIndex, values, newValue);
  };

  const handleTagClose = (e, valueToRemove) => {
    e.stopPropagation();
    if (values.length <= 1) return;
    const newValues = values.filter((value) => value !== valueToRemove);
    const isDefault = valueToRemove === value;
    const newValue = isDefault
      ? newValues[Math.floor(newValues.length / 2)]
      : value;
    onValueChange(parameterIndex, newValues, newValue);
  };

  const validate = (value) => {
    switch (parameterType) {
      case "Float":
        try {
          const floatValue = parseFloat(value);
          // Check if min/max values are respected
          if (
            isNaN(floatValue) ||
            !isFinite(floatValue) ||
            floatValue < minValueParsed ||
            floatValue > maxValueParsed
          ) {
            setErrorMessage(`Must be between ${minValue} and ${maxValue}.`);
            return false;
          }
        } catch {
          // Check if float
          setErrorMessage(`Must be a float number.`);
          return false;
        }
        break;
      case "Integer":
        // Check if float
        if (String(value).includes(".")) {
          setErrorMessage(`Must be an integer value.`);
          return false;
        }
        const intValue = parseInt(value);
        // Check if min/max values are respected
        if (
          isNaN(intValue) ||
          !isFinite(intValue) ||
          intValue < minValueParsed ||
          intValue > maxValueParsed
        ) {
          setErrorMessage(`Must be between ${minValue} and ${maxValue}.`);
          return false;
        }
        break;
      case "Choice":
        // Add validation for choice type
        const stringValue = String(value).trim();
        if (!defaultOptions.includes(stringValue)) {
          setErrorMessage(`Must be one between: ${defaultOptions.join(", ")}.`);
          return false;
        }
        break;
      default:
        console.log(`ParameterType ${parameterType} not recognized.`);
        return false;
    }
    setErrorMessage("");
    return true;
  };

  return (
    <div>
      {errorMessage && (
        <div style={{ color: "red", marginBottom: "10px" }}>{errorMessage}</div>
      )}
      <Select
        mode="tags"
        style={{
          width: "100%",
        }}
        value={[...values]}
        options={defaultOptionsDict}
        placeholder=""
        onChange={handleChange}
        onKeyPress={(event) => handleKeyPress(event)}
        onBlur={() => handleBlur()}
        className="grey-select no-arrow"
        getPopupContainer={(trigger) => trigger.parentElement}
        tagRender={(props) => (
          <div
            className="ant-select-tag"
            style={{
              color: String(props.value) === value ? "orange" : "#fff",
            }}
            onClick={(e) => {
              const isInsideTag = e.target.closest(
                ".ant-select-selection-item"
              );
              if (!isInsideTag) {
                e.preventDefault();
                e.stopPropagation();
                const select = e.currentTarget.closest(".ant-select-selector");
                if (select) {
                  select.focus();
                }
              }
              handleTagClick(e, props.value);
            }}
          >
            {props.label}
            <span
              className="ant-select-tag-close-icon"
              onClick={(e) => handleTagClose(e, props.value)}
            >
              <IoMdClose />
            </span>
          </div>
        )}
        open={false}
      />
    </div>
  );
};

export default ParameterSelector;
