/**
 * @since 2024-05-04
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import "./LabelBox.scss";

const LabelBox = ({ name, value }) => {
  /* Similar to InputBox styling, but it does not allow edits */

  return (
    <div key={`LabelBox.${name}`} className="label-box">
      {value}
    </div>
  );
};

export default LabelBox;
