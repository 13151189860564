/**
 * @since 2023-05-15
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import SearchBox from "./SearchBox";

export default SearchBox;
