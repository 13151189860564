/**
 * @since 2023-07-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import PanelReportViewer from "components/Shared/PanelReportViewer";
import SectionScoresError from "components/Shared/SectionScoresError";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import {
  createImportedAnalysis,
  getStatusImportedAnalysis,
  loadImportedReports,
  waitForImportedAnalysis,
} from "dataHandling/imported";
import { useEffect, useState } from "react";

const PanelReports = ({ email, importedId }) => {
  //region States

  const [status, setStatus] = useState("");
  const [reportsData, setReportsData] = useState({});
  const [reportIndex, setReportIndex] = useState(1);

  //endregion

  //region Use Effects
  useEffect(() => {
    fetchData();
  }, [importedId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    let newStatus = await getStatusImportedAnalysis(email, importedId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForImportedAnalysis(email, importedId);
    }
    if (newStatus === "OK") {
      const newReportsData = await loadImportedReports(email, importedId);
      setReportsData(newReportsData);
    }
    setStatus(newStatus);
  };

  const handleCreateAnalysis = async () => {
    setStatus("WAITING");
    await createImportedAnalysis(email, importedId);
    fetchData();
  };

  //endregion

  return (
    <>
      {status === "NEW" && (
        <ButtonBlue
          label="CREATE REPORTS"
          alignment="left"
          onClick={handleCreateAnalysis}
        />
      )}
      {status === "WAITING" && <TextWait />}
      {status === "ERROR" && (
        <SectionScoresError onClick={handleCreateAnalysis} />
      )}
      {status === "OK" && (
        <>
          <PanelReportViewer
            reportsData={reportsData}
            showRecommendations={true}
          />
          <br />
          <br />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelReports;
