/**
 * @since 2024-05-26
 * @maintainer Francesco Parrella
 * @author Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableWithPagination from "components/Shared/TableWithPagination";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toDollars, toFloat, toInteger, toPercentage } from "utils/formatting";
import { parseNumber } from "utils/parsing";
import "./ItemTablePerformance.scss";

const ItemTablePerformance = ({
  itemData,
  snapshot,
  strategyName,
  category,
}) => {
  const intl = useIntl();

  const [dataTable, setDataTable] = useState(null);

  const listColumns_Default = [
    {
      title: "",
      dataIndex: "index",
      key: "index",
      align: "left",
    },
    {
      title: "Profit & Loss",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-profit&loss",
      }),
      sorter: (a, b) => parseNumber(a.profitLoss$) - parseNumber(b.profitLoss$),
      showSorterTooltip: false,
      children: [
        {
          title: "",
          dataIndex: "profitLoss$",
          key: "profitLoss$",
          align: "right",
        },
        {
          title: "",
          dataIndex: "profitLoss%",
          key: "profitLoss%",
          width: "70px",
          align: "right",
        },
      ],
    },
    {
      title: "Gross Notional",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-grossnotional",
      }),
      sorter: (a, b) =>
        parseNumber(a.positionGross$) - parseNumber(b.positionGross$),
      showSorterTooltip: false,
      children: [
        {
          title: "",
          dataIndex: "positionGross$",
          key: "positionGross$",
          align: "right",
        },
        {
          title: "",
          dataIndex: "positionGross%",
          key: "positionGross%",
          width: "70px",
          align: "right",
        },
      ],
    },
    {
      title: "Net Notional",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-netnotional",
      }),
      sorter: (a, b) =>
        parseNumber(a.positionNet$) - parseNumber(b.positionNet$),
      showSorterTooltip: false,
      children: [
        {
          title: "",
          dataIndex: "positionNet$",
          key: "positionNet$",
          align: "right",
        },
        {
          title: "",
          dataIndex: "positionNet%",
          key: "positionNet%",
          width: "70px",
          align: "right",
        },
      ],
    },
    {
      title: "Volatility",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-volatility",
      }),
      sorter: (a, b) => parseNumber(a.volatility$) - parseNumber(b.volatility$),
      showSorterTooltip: false,
      children: [
        {
          title: "",
          dataIndex: "volatility$",
          key: "volatility$",
          align: "right",
        },
        {
          title: "",
          dataIndex: "volatility%",
          key: "volatility%",
          width: "70px",
          align: "right",
        },
      ],
    },
    {
      title: "Value At Risk",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-valueatrisk",
      }),
      sorter: (a, b) =>
        parseNumber(a.valueAtRisk$) - parseNumber(b.valueAtRisk$),
      showSorterTooltip: false,
      children: [
        {
          title: "",
          dataIndex: "valueAtRisk$",
          key: "valueAtRisk$",
          align: "right",
        },
        {
          title: "",
          dataIndex: "valueAtRisk%",
          key: "valueAtRisk%",
          width: "70px",
          align: "right",
        },
      ],
    },
  ];
  const listColumns_ByInstrument = [
    ...listColumns_Default.slice(0, 1),
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-position",
      }),
      sorter: (a, b) => parseNumber(a.position) - parseNumber(b.position),
      showSorterTooltip: false,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "right",
      tooltip: intl.formatMessage({
        id: "live-performance-price",
      }),
      sorter: (a, b) => parseNumber(a.price) - parseNumber(b.price),
      showSorterTooltip: false,
    },
    ...listColumns_Default.slice(1),
  ];

  const [listColumns, setListColumns] = useState(listColumns_Default);

  useEffect(() => {
    if (!itemData || !snapshot || !strategyName || !category) return;
    convertToAntTableData(snapshot, strategyName, category);
  }, [itemData, snapshot, strategyName, category]);

  useEffect(() => {
    if (!itemData || !snapshot || !strategyName || !category) return;
    setListColumns(
      category == "By Instrument"
        ? listColumns_ByInstrument
        : listColumns_Default
    );
    convertToAntTableData(snapshot, strategyName, category);
  }, [category]);

  function convertToAntTableData(snapshot, strategyName, category) {
    const dictDataForTable = itemData[snapshot][strategyName][category];
    const listRows = Object.keys(dictDataForTable);
    const dataTable = listRows.map((indexName, rowIndex) => {
      let tableItem = {
        key: rowIndex,
        index: indexName,
        profitLoss$: toDollars(
          dictDataForTable[indexName]["Profit & Loss"]["$"]
        ),
        "profitLoss%": toPercentage(
          dictDataForTable[indexName]["Profit & Loss"]["%"]
        ),
        positionGross$: toDollars(
          dictDataForTable[indexName]["Gross Notional"]["$"]
        ),
        "positionGross%": toPercentage(
          dictDataForTable[indexName]["Gross Notional"]["%"]
        ),
        positionNet$: toDollars(
          dictDataForTable[indexName]["Net Notional"]["$"]
        ),
        "positionNet%": toPercentage(
          dictDataForTable[indexName]["Net Notional"]["%"]
        ),
        volatility$: toDollars(dictDataForTable[indexName]["Volatility"]["$"]),
        "volatility%": toPercentage(
          dictDataForTable[indexName]["Volatility"]["%"]
        ),
        valueAtRisk$: toDollars(
          dictDataForTable[indexName]["Value At Risk"]["$"]
        ),
        "valueAtRisk%": toPercentage(
          dictDataForTable[indexName]["Value At Risk"]["%"]
        ),
      };
      if (category == "By Instrument") {
        tableItem = {
          ...tableItem,
          position: toInteger(dictDataForTable[indexName]["Position"]),
          price: toFloat(dictDataForTable[indexName]["Price"]),
        };
      }
      return tableItem;
    });
    setDataTable(dataTable);
  }

  return (
    <div className="item-table-performance">
      <TableWithPagination dataTable={dataTable} columns={listColumns} />
    </div>
  );
};
export default ItemTablePerformance;
