/**
 * @since 2023-08-08
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ItemTableSimStats from "./ItemTableSimStats";

export default ItemTableSimStats;
