/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import SectionScoresError from "components/Shared/SectionScoresError";
import SectionScoresStart from "components/Shared/SectionScoresStart";
import SectionScoresStop from "components/Shared/SectionScoresStop";
import TableScoresWithFilters from "components/Shared/TableScoresWithFilters";
import TextCopyright from "components/Shared/TextCopyright";
import TextMessage from "components/Shared/TextMessage";
import {
  getStatusStrategyReports,
  loadStrategyScores,
  startStrategyReports,
  stopStrategyReports,
  waitForStrategyReports,
} from "dataHandling/strategy";
import { useEffect, useState } from "react";

const PanelScores = ({ email, strategyId, onReportsClick }) => {
  //region States

  const [status, setStatus] = useState("");
  const [scoresTable, setScoresTable] = useState(null);

  //endregion

  //region Use Effects

  useEffect(() => {
    fetchData();
  }, [strategyId, email]);

  //endregion

  //region Methods

  const fetchData = async () => {
    let newStatus = await getStatusStrategyReports(email, strategyId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForStrategyReports(email, strategyId);
    }
    if (newStatus === "OK") {
      const newScoresTable = await loadStrategyScores(email, strategyId);
      setScoresTable(newScoresTable);
    }
    setStatus(newStatus);
  };

  const startScoresAnalysis = async () => {
    setStatus("WAITING");
    await startStrategyReports(email, strategyId);
    fetchData();
  };

  const stopScoresAnalysis = async () => {
    await stopStrategyReports(email, strategyId);
    setStatus("NEW");
  };

  //endregion

  return (
    <>
      {status === "ANALYSIS" && (
        <TextMessage message="Waiting for the analysis completion..." />
      )}
      {status === "NEW" && <SectionScoresStart onClick={startScoresAnalysis} />}
      {status === "WAITING" && (
        <SectionScoresStop onClick={stopScoresAnalysis} />
      )}
      {status === "ERROR" && (
        <SectionScoresError onClick={startScoresAnalysis} />
      )}
      {status === "OK" && (
        <>
          <TableScoresWithFilters
            scoresTable={scoresTable}
            showRecommendations={true}
          />
          <br />
          <br />
          <ButtonBlue label="SEE REPORTS" onClick={onReportsClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};

export default PanelScores;
