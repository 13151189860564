/**
 * @since 2023-11-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarActionsAnalysis from "./BarActionsAnalysis";

export default BarActionsAnalysis;
