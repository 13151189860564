/**
 * @since 2023-09-29
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableLiveTrades from "./TableLiveTrades";

export default TableLiveTrades;
