/**
 * @since: 2023-10-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: AlgoTraders, All rights reserved
 */

import { WarningFilled } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./ErrorPage.scss";
interface ErrorPageProps {
  errorMessage: string;
}

export function ErrorPage(props: ErrorPageProps) {
  return (
    <p className="error-text">
      {props.errorMessage ? (
        <>
          {props.errorMessage}
          <ErrorIcon />
        </>
      ) : (
        <>
          An error occurred.
          <ErrorIcon />
          <DashboardLink />
        </>
      )}
    </p>
  );
}

function ErrorIcon() {
  return <WarningFilled style={{ marginLeft: "30px" }} />;
}
function DashboardLink() {
  return (
    <Link to="/" className="dashboard-nav-text">
      Go to Dashboard
    </Link>
  );
}
