/**
 * @since 2024-05-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableWithPagination from "components/Shared/TableWithPagination";
import { useIntl } from "react-intl";
import { parseNumber } from "utils/parsing";

const TableSimulationStats = ({ isLoading, data }) => {
  const intl = useIntl();

  const dataSource = data.Items.map((rowItems, rowIndex) => {
    let rowObject = { key: rowIndex };
    rowItems.forEach((item, colIndex) => {
      rowObject[data.Columns[colIndex]] = item;
    });
    return rowObject;
  });

  const listTooltipNames = [
    "performance-table-name",
    "performance-table-pnl",
    "performance-table-traded",
    "performance-table-win",
    "performance-table-averagereturn",
    "performance-table-minreturn",
    "performance-table-maxreturn",
    "performance-table-minposition",
    "performance-table-maxposition",
    "performance-table-return",
    "performance-table-commission",
    "performance-table-slippage",
    "performance-table-volatility",
    "performance-table-mdd",
    "performance-table-retmdd",
    "performance-table-retcst",
    "performance-table-sharpe",
  ];

  const hasMultipleRows = dataSource.length > 1;

  const tableColumns = data.Columns.map((column, index) => {
    if (index === 0) {
      return {
        title: column,
        dataIndex: column,
        key: column,
        tooltip: intl.formatMessage({
          id: listTooltipNames[index],
        }),
        sorter: hasMultipleRows
          ? (a, b) => a[column].localeCompare(b[column])
          : undefined,
        showSorterTooltip: false,
        align: "left",
      };
    } else {
      return {
        title: column,
        dataIndex: column,
        key: column,
        tooltip: intl.formatMessage({
          id: listTooltipNames[index],
        }),
        sorter: hasMultipleRows
          ? (a, b) => parseNumber(a[column]) - parseNumber(b[column])
          : undefined,
        showSorterTooltip: false,
        align: "right",
      };
    }
  });

  return (
    <div className="table-stats">
      {!isLoading && (
        <TableWithPagination columns={tableColumns} dataTable={dataSource} />
      )}
    </div>
  );
};

export default TableSimulationStats;
