/**
 * @since 2023-12-22
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ChartReturns from "components/Shared/ChartReturns";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import ItemText from "components/Shared/Report/Items/ItemText";
import { useEffect, useState } from "react";
import "./ItemIndicatorStats.scss";

const ItemIndicatorStats = ({ itemData }) => {
  const defaultStrategy = "<Select Strategy>";

  const [listStrategies, setListStrategies] = useState([defaultStrategy]);
  const [strategy, setStrategy] = useState(defaultStrategy);
  const [listRules, setListRules] = useState([]);
  const [rule, setRule] = useState(null);
  const [listInstruments, setListInstruments] = useState([]);
  const [instrument, setInstrument] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (!itemData) return;
    const listStrategies = [defaultStrategy, ...Object.keys(itemData)];
    setListStrategies(listStrategies);
    setStrategy(defaultStrategy);
    refreshChart(defaultStrategy, rule, instrument);
  }, [itemData]);

  useEffect(() => {
    if (strategy == defaultStrategy) {
      setChartData(null);
      setRule(null);
      setInstrument(null);
      setListRules([]);
      setListInstruments([]);
      return;
    }
    const rulesData = itemData[strategy];
    const listRules = Object.keys(rulesData);
    setListRules(listRules);
    const newRule = listRules[0];
    setRule(newRule);
  }, [strategy]);

  useEffect(() => {
    if (strategy == defaultStrategy || !rule || !instrument) return;
    refreshChart(strategy, rule, instrument);
  }, [instrument]);

  useEffect(() => {
    if (strategy == defaultStrategy || !rule) return;
    const instrumentsData = itemData[strategy][rule]["IndicatorLeftValues"];
    const listInstruments = Object.keys(instrumentsData);
    setListInstruments(listInstruments);
    const newInstrument = listInstruments[0];
    setInstrument(newInstrument);
    refreshChart(strategy, rule, newInstrument);
  }, [rule]);

  const handleInstrumentChange = async (name, newInstrument) => {
    setInstrument(newInstrument);
  };

  const handleRuleChange = async (name, newRule) => {
    setRule(newRule);
  };

  const handleStrategyChange = async (name, newStrategy) => {
    setStrategy(newStrategy);
  };

  const refreshChart = async (strategy, rule, instrument) => {
    if (strategy == defaultStrategy || !rule || !instrument) return;
    if (!itemData || !itemData[strategy] || !itemData[strategy][rule]) return;
    const ruleData = itemData[strategy][rule];
    const listChartValues = [
      {
        Name: ruleData["IndicatorLeftName"],
        Description: "",
        Returns: ruleData["IndicatorLeftValues"][instrument]["Values"],
      },
    ];
    if (ruleData["IndicatorRightName"]) {
      listChartValues.push({
        Name: ruleData["IndicatorRightName"],
        Description: "",
        Returns: ruleData["IndicatorRightValues"][instrument]["Values"],
      });
    }
    const dictChartData = {
      Title: rule,
      Dates:
        itemData[strategy][rule]["IndicatorLeftValues"][instrument][
          "DateTimes"
        ],
      Values: listChartValues,
    };
    setChartData(dictChartData);
  };

  return (
    <div className="item-indicator-stats">
      {listStrategies.length <= 1 ? (
        <ItemText itemData="There is no data." />
      ) : (
        <div className="indicator-stats-container">
          <div style={{ width: "240px", marginRight: "10px" }}>
            <MultipleChoiceBox
              name="strategy-selection"
              choices={listStrategies}
              value={strategy}
              onUpdate={handleStrategyChange}
            />
          </div>
          <div style={{ width: "200px", marginRight: "10px" }}>
            <MultipleChoiceBox
              name="instrument-selection"
              choices={listInstruments}
              value={instrument}
              onUpdate={handleInstrumentChange}
            />
          </div>
          <div style={{ width: "100%" }}>
            <MultipleChoiceBox
              name="category-selection"
              choices={listRules}
              value={rule}
              onUpdate={handleRuleChange}
            />
          </div>
        </div>
      )}
      {chartData && (
        <div className="chart-container">
          <ChartReturns chartData={chartData} includeY0={false} />
        </div>
      )}
    </div>
  );
};
export default ItemIndicatorStats;
