/**
 * @since 2024-05-06
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import DisplayBox from "components/Shared/DisplayBox";
import InputBox from "components/Shared/InputBox";
import LabelBox from "components/Shared/LabelBox";
import { saveProfileSettings } from "dataHandling/general";
import { useEffect, useState } from "react";
import {
  validateName,
  validatePhoneNumber,
  validateUrl,
} from "utils/validation";
import ModalPasswordChange from "./ModalPasswordChange";
import "./PanelProfile.scss";

const PanelProfile = ({ email, menuItems, onCancel, onSettingsChanged }) => {
  const getDefaultSettings = () => {
    return {
      Email: {
        type: "LabelBox",
        label: "Email",
        fieldName: "email",
        value: menuItems.User.email,
      },
      Password: {
        type: "ChangePassword",
        label: "Password",
        fieldName: "password",
        value: null,
      },
      FirstName: {
        type: "InputBox",
        label: "First Name",
        fieldName: "firstName",
        value: menuItems.User.firstName,
      },
      LastName: {
        type: "InputBox",
        label: "Last Name",
        fieldName: "lastName",
        value: menuItems.User.lastName,
      },
      CompanyName: {
        type: "InputBox",
        label: "Company Name",
        fieldName: "companyName",
        value: menuItems.User.companyName,
      },
      CompanyWebsite: {
        type: "InputBox",
        label: "Company Website",
        fieldName: "companyWebsite",
        value: menuItems.User.companyWebsite,
      },
      PhoneNumber: {
        type: "InputBox",
        label: "Phone Number",
        fieldName: "phoneNumber",
        value: menuItems.User.phoneNumber,
      },
    };
  };

  const [profileSettingsFields, setProfileSettingsFields] = useState(
    getDefaultSettings()
  );
  const listProfileSettings = [
    "Email",
    "Password",
    "FirstName",
    "LastName",
    "CompanyName",
    "CompanyWebsite",
    "PhoneNumber",
  ];
  const [listValidationErrors, setListValidationErrors] = useState([]);
  const [areThereChanges, setAreThereChanges] = useState(false);
  const [isModalPasswordChangeOpen, setIsModalPasswordChangeOpen] =
    useState(false);

  //region UseEffects

  useEffect(() => {
    validateFields(profileSettingsFields);
    updateAreThereChanges(profileSettingsFields);
  }, [profileSettingsFields]);

  //endregion

  //region Methods

  const cancel = () => {
    setProfileSettingsFields(getDefaultSettings());
    onCancel();
  };

  const handleProfileInputChange = (settingName, settingNewValue) => {
    let newValue = String(settingNewValue);
    setProfileSettingsFields({
      ...profileSettingsFields,
      [settingName]: {
        ...profileSettingsFields[settingName],
        value: newValue,
      },
    });
  };

  const onPasswordCancel = () => {
    setIsModalPasswordChangeOpen(false);
  };

  const renderInputField = (name) => {
    const item = profileSettingsFields[name];
    switch (item.type) {
      case "LabelBox":
        return <LabelBox name={name} value={item.value} />;
      case "InputBox":
        return (
          <InputBox
            name={name}
            value={item.value}
            maxLength={30}
            onUpdate={handleProfileInputChange}
          />
        );
      case "ChangePassword":
        return (
          <DisplayBox
            name="ChangePassword"
            value={
              profileSettingsFields["Password"].value === null
                ? "<Change>"
                : "<Changed>"
            }
            onClick={showChangePassword}
          />
        );
      default:
        console.log(`Error: ${name} has unknown type ${item.type}`);
    }
  };

  const saveSettings = async () => {
    const dictProfileSettings = Object.fromEntries(
      Object.keys(profileSettingsFields).map((key) => [
        profileSettingsFields[key].fieldName,
        profileSettingsFields[key].value,
      ])
    );
    await saveProfileSettings(email, dictProfileSettings);
    onSettingsChanged();
  };

  const setNewPassword = (newPassword) => {
    setProfileSettingsFields({
      ...profileSettingsFields,
      Password: {
        ...profileSettingsFields.Password,
        value: newPassword,
      },
    });
    setIsModalPasswordChangeOpen(false);
  };

  const showChangePassword = () => {
    setIsModalPasswordChangeOpen(true);
  };

  const showErrors = () => {
    return (
      <div className="errors-container">
        {listValidationErrors.map((error) => (
          <div className="error">{error}</div>
        ))}
      </div>
    );
  };

  const updateAreThereChanges = (profileSettingsFields) => {
    const areThereChanges = listProfileSettings.some((setting) => {
      if (setting === "Password") {
        return profileSettingsFields[setting].value !== null;
      } else {
        return (
          profileSettingsFields[setting].value !==
          menuItems["User"][profileSettingsFields[setting].fieldName]
        );
      }
    });
    setAreThereChanges(areThereChanges);
  };

  const validateFields = (profileSettingsFields) => {
    const listErrors = [];
    // First Name
    const firstName = String(profileSettingsFields["FirstName"].value).trim();
    let { isValid, errorMessage } = validateName(firstName, 2, 30);
    if (!isValid) listErrors.push(`First Name: ${errorMessage}`);
    // Last Name
    const lastName = String(profileSettingsFields["LastName"].value).trim();
    ({ isValid, errorMessage } = validateName(lastName, 2, 30));
    if (!isValid) listErrors.push(`Last Name: ${errorMessage}`);
    // Company Name
    const companyName = String(
      profileSettingsFields["CompanyName"].value
    ).trim();
    ({ isValid, errorMessage } = validateName(companyName, 2, 30));
    if (!isValid) listErrors.push(`Company Name: ${errorMessage}`);
    // Company Website
    const companyWebsite = String(
      profileSettingsFields["CompanyWebsite"].value
    ).trim();
    ({ isValid, errorMessage } = validateUrl(companyWebsite));
    if (!isValid) listErrors.push(`Company Website: ${errorMessage}`);
    // Phone Number
    const phoneNumber = String(
      profileSettingsFields["PhoneNumber"].value
    ).trim();
    ({ isValid, errorMessage } = validatePhoneNumber(phoneNumber));
    if (!isValid) listErrors.push(`Phone Number: ${errorMessage}`);
    setListValidationErrors(listErrors);
  };

  //endregion

  return (
    <div className="panel-profile">
      <div
        style={{
          width: "500px",
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "left",
          color: "rgba(255, 255, 255, 0.5)",
        }}
      >
        {listProfileSettings.map((setting) => (
          <div className="setting-item" key={`${setting}.Outer`} styl>
            <div className="setting-row" key={`${setting}.Main`}>
              <div className="setting-name" key={`${setting}.Name`}>
                {profileSettingsFields[setting].label}
              </div>
              <div className="setting-value" key={`${setting}-Value`}>
                {renderInputField(setting)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showErrors()}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "100%",
        }}
      >
        <BarSaveCancel
          onCancel={cancel}
          onSave={saveSettings}
          isSaveDisabled={!areThereChanges || listValidationErrors.length > 0}
          errorMessage="No changes made or errors detected"
        />
      </div>
      <ModalPasswordChange
        isOpen={isModalPasswordChangeOpen}
        onCancel={onPasswordCancel}
        onPasswordChange={setNewPassword}
      />
    </div>
  );
};

export default PanelProfile;
