/**
 * @since 2023-08-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableWithPagination from "components/Shared/TableWithPagination";
import { useEffect, useState } from "react";
import { parseNumber } from "utils/parsing";
import "./ItemTableDataFrame.scss";

const ItemTableDataFrame = ({ itemData }) => {
  const [dataTable, setDataTable] = useState(null);

  const columns = [
    {
      title: "Index",
      dataIndex: "Index",
      key: "Index",
      sorter: (a, b) => a["Index"].localeCompare(b["Index"]),
      showSorterTooltip: false,
    },
    ...itemData.Columns.map((col, idx) => ({
      title: col,
      dataIndex: `value${idx}`,
      key: `value${idx}`,
      sorter: (a, b) => parseNumber(a[col]) - parseNumber(b[col]),
      showSorterTooltip: false,
    })),
  ];

  useEffect(() => {
    if (itemData === null) return;
    setDataTable(convertToAntTableData(itemData));
  }, [itemData]);

  function convertToAntTableData(data) {
    return data.Index.map((indexName, rowIndex) => {
      let tableItem = { key: rowIndex, Index: indexName };

      data.Values[rowIndex].forEach((value, valueIndex) => {
        tableItem[`value${valueIndex}`] = value;
      });

      return tableItem;
    });
  }

  return <TableWithPagination dataTable={dataTable} columns={columns} />;
};
export default ItemTableDataFrame;
