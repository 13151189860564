/**
 * @since 2023-08-01
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ModalReturnsSelector from "./ModalReturnsSelector";

export default ModalReturnsSelector;
