/*
@since: 2023-8-17
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: AlgoTraders, All rights reserved
*/

export const BASE_BACKEND_URL = "https://dashboard.centrallimit.ai:5001";
// export const BASE_BACKEND_URL = "http://localhost:8000";
// export const BASE_BACKEND_URL = "https://dashboard.centrallimit.ai:5001";
