/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import PanelReportViewer from "components/Shared/PanelReportViewer";
import SectionScoresError from "components/Shared/SectionScoresError";
import SectionScoresStart from "components/Shared/SectionScoresStart";
import SectionScoresStop from "components/Shared/SectionScoresStop";
import TextCopyright from "components/Shared/TextCopyright";
import TextMessage from "components/Shared/TextMessage";
import {
  getStatusStrategyReports,
  loadStrategyReports,
  startStrategyReports,
  stopStrategyReports,
  waitForStrategyReports,
} from "dataHandling/strategy";
import { useEffect, useState } from "react";

const PanelReports = ({ email, strategyId }) => {
  //region States

  const [status, setStatus] = useState("");
  const [reportsData, setReportsData] = useState({});

  //endregion

  //region Use Effects
  useEffect(() => {
    fetchData();
  }, [strategyId, email]);

  //endregion

  //region Methods

  //endregion

  const fetchData = async () => {
    let newStatus = await getStatusStrategyReports(email, strategyId);
    if (newStatus === "WAITING") {
      setStatus(newStatus);
      newStatus = await waitForStrategyReports(email, strategyId);
    }
    if (newStatus === "OK") {
      const newReportsData = await loadStrategyReports(email, strategyId);
      setReportsData(newReportsData);
    }
    setStatus(newStatus);
  };

  const startScoresAnalysis = async () => {
    setStatus("WAITING");
    await startStrategyReports(email, strategyId);
    fetchData();
  };

  const stopScoresAnalysis = async () => {
    await stopStrategyReports(email, strategyId);
    setStatus("NEW");
  };

  return (
    <>
      {status === "ANALYSIS" && (
        <TextMessage message="Waiting for the analysis completion..." />
      )}
      {status === "NEW" && <SectionScoresStart onClick={startScoresAnalysis} />}
      {status === "WAITING" && (
        <SectionScoresStop onClick={stopScoresAnalysis} />
      )}
      {status === "ERROR" && (
        <SectionScoresError onClick={startScoresAnalysis} />
      )}
      {status === "OK" && (
        <>
          <PanelReportViewer
            reportsData={reportsData}
            showRecommendations={true}
          />
          <br />
          <br />
          <TextCopyright />
        </>
      )}
      ;
    </>
  );
};

export default PanelReports;
