/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import * as React from "react";
import "./TableMonthlyReturns.scss";

interface Props {
  isLoading: boolean;

  data: {
    Years: number[];
    Months: string[];
    Returns: number[][];
  };
}

const TableMonthlyReturns: React.FC<Props> = ({ isLoading, data }) => {
  const {
    Years: years = [],
    Months: months = [],
    Returns: returns = [],
  } = data;

  const getGradientColor = (value: number): string => {
    const alpha = Math.min(Math.abs(value), 10) / 28.0;
    if (value >= 0) {
      // Green
      return `rgb(170, 246, 23, ${alpha}`;
    }
    // Red
    return `rgb(255, 66, 66, ${alpha}`;
  };

  const formatValue = (value: string): string => {
    if (value == "" || value == "nan") return "-";
    return value + "%";
  };

  return (
    <>
      {!isLoading && (
        <div className="month-table fancy-scroll">
          <table>
            <tbody>
              <tr>
                <td></td>
                {months.map((month, i) => (
                  <td key={`mt-month-cell__${i}`}>{month}</td>
                ))}
              </tr>
              {years.map((year, i) => (
                <tr key={`mt-row__${i}`}>
                  <td>{year}</td>
                  {new Array(13).fill("").map((_, j) => (
                    <td
                      key={`mt-cell__${j}`}
                      style={
                        {
                          "--cell-color": getGradientColor(returns?.[i]?.[j]),
                        } as any
                      }
                    >
                      <span>{formatValue(returns?.[i]?.[j].toString())}</span>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default TableMonthlyReturns;
