/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";

const createStrategyAnalysis = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/createStrategyAnalysis/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const createStrategySettings = async (email) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/createStrategySettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const deleteStrategySettings = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/deleteStrategySettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const duplicateStrategySettings = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/duplicateStrategySettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const getStatusStrategyAnalysis = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/getStatusStrategyAnalysis/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const getStatusStrategyReports = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/getStatusStrategyReports/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadStrategyAnalysis = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadStrategyAnalysis/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadStrategyPreview = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadStrategyPreview/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadStrategyReports = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadStrategyReports/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadStrategyScores = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadStrategyScores/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadStrategySettings = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadStrategySettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const saveStrategySettings = async (email, strategyId, strategySettings) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/saveStrategySettings/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, strategyId, strategySettings }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const startStrategyReports = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/startStrategyReports/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const stopStrategyReports = async (email, strategyId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/stopStrategyReports/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, strategyId }),
      }
    );
    await response.json();
  } catch (error) {
    console.error(error);
  }
};

const waitForStrategyAnalysis = async (email, strategyId, delay = 1000) => {
  const status = await getStatusStrategyAnalysis(email, strategyId);
  if (status === "WAITING") {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const result = await waitForStrategyAnalysis(email, strategyId, delay);
        resolve(result);
      }, delay);
    });
  } else {
    return status;
  }
};

const waitForStrategyReports = async (email, strategyId, delay = 1000) => {
  const status = await getStatusStrategyReports(email, strategyId);
  if (status === "WAITING") {
    return new Promise((resolve) => {
      setTimeout(async () => {
        const result = await waitForStrategyReports(email, strategyId, delay);
        resolve(result);
      }, delay);
    });
  } else {
    return status;
  }
};

export {
  createStrategyAnalysis,
  createStrategySettings,
  deleteStrategySettings,
  duplicateStrategySettings,
  getStatusStrategyAnalysis,
  getStatusStrategyReports,
  loadStrategyAnalysis,
  loadStrategyPreview,
  loadStrategyReports,
  loadStrategyScores,
  loadStrategySettings,
  saveStrategySettings,
  startStrategyReports,
  stopStrategyReports,
  waitForStrategyAnalysis,
  waitForStrategyReports,
};
