/**
 * @since 2023-07-31
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { BASE_BACKEND_URL } from "./constants";

const loadPremiumAnalysis = async (email, portfolioId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadPremiumAnalysis/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, portfolioId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPremiumPreview = async (email, portfolioId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadPremiumPreview/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, portfolioId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPremiumSettings = async (email, portfolioId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadPremiumSettings/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, portfolioId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPremiumReports = async (email, portfolioId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadPremiumReports/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, portfolioId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

const loadPremiumScores = async (email, portfolioId) => {
  try {
    const response = await fetch(
      `${BASE_BACKEND_URL}/strategy/loadPremiumScores/`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, portfolioId }),
      }
    );
    return await response.json();
  } catch (error) {
    console.error(error);
  }
};

export {
  loadPremiumAnalysis,
  loadPremiumPreview,
  loadPremiumReports,
  loadPremiumScores,
  loadPremiumSettings,
};
