/**
 * @since 2023-08-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import * as React from "react";

import "./TableReturns.scss";

interface Props {
  returnsData: {
    Dates: string[];
    Columns: string[];
    Data: (string | number)[][];
  };
}

const TableReturns: React.FC<Props> = ({ returnsData }) => {
  if (!returnsData) return null;

  const { Dates: dates, Columns: columns, Data: data } = returnsData;

  if (!columns || !data) return null;

  return (
    <>
      <div className="returns-table fancy-scroll">
        <table>
          <thead>
            <tr>
              <th key={`st-h__0`}>
                <span>{"Date"}</span>
              </th>
              {columns.map((column, index) => (
                <th key={`st-h__${index}`}>
                  <span>{column}</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((rowItems, i) => (
              <tr key={`st-row__${i}`}>
                <td key={`st-cell__0`} data-label={dates[i]}>
                  {dates[i]}
                </td>
                {rowItems.map((item, j) => (
                  <td key={`st-cell__${j}`} data-label={columns[j]}>
                    <span>{item}</span>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableReturns;
