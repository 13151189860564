/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ButtonBlue from "components/Shared/ButtonBlue";
import EditableSection from "components/Shared/EditableSection";
import TextCopyright from "components/Shared/TextCopyright";
import TextWait from "components/Shared/TextWait";
import {
  loadImportedSettings,
  saveImportedSettings,
} from "dataHandling/imported";
import { useEffect, useState } from "react";
import PreviewChart from "./PreviewChart";
import SettingsTable from "./TableSettings";

const PanelSettings = ({
  email,
  importedId,
  importedName,
  listImportedNames,
  configuration,
  onImportedNameChange,
  onAnalysisClick,
}) => {
  const [importedSettings, setImportedSettings] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit_Settings, setIsEdit_Settings] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const newImportedSettings = await loadImportedSettings(email, importedId);
      setImportedSettings(newImportedSettings);
      setIsLoading(false);
    })();
  }, [importedName]);

  const updateSettings = async (settings) => {
    const oldImportedName = importedName;
    const newImportedName = settings.ImportedName;
    const importedSettingsEdited = {
      ...importedSettings,
      Settings: settings,
    };
    save(importedSettingsEdited);
    // Reload the imported
    if (oldImportedName !== newImportedName) {
      onImportedNameChange(oldImportedName, newImportedName);
    }
  };

  const save = async (importedSettingsEdited) => {
    await saveImportedSettings(email, importedId, importedSettingsEdited);
    setImportedSettings(importedSettingsEdited);
  };

  return (
    <>
      {isLoading ? (
        <TextWait />
      ) : (
        <>
          <EditableSection
            title={"SETTINGS"}
            isOpened={true}
            canEdit={true}
            isEdit={isEdit_Settings}
            updateIsEdit={setIsEdit_Settings}
          >
            <SettingsTable
              settings={importedSettings}
              listImportedNames={listImportedNames}
              configuration={configuration}
              onSettingsChange={updateSettings}
              isEdit={isEdit_Settings}
              onIsEditUpdate={setIsEdit_Settings}
            />
          </EditableSection>
          <EditableSection
            title={"PREVIEW"}
            isOpened={true}
            canEdit={false}
            isEdit={false}
          >
            <div>
              <PreviewChart email={email} importedSettings={importedSettings} />
            </div>
          </EditableSection>
          <br />
          <br />
          <ButtonBlue label="SEE ANALYSIS" onClick={onAnalysisClick} />
          <TextCopyright />
        </>
      )}
    </>
  );
};
export default PanelSettings;
