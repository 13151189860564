/**
 * @since 2023-10-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import NotificationCheckboxGroup from "components/Shared/NotificationCheckboxGroup";
import Tooltip from "components/Shared/Tooltip";
import { notifyEmailTest } from "dataHandling/signal";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { validateEmail } from "utils/validation";
import "./TableSettings_Email.scss";

const TableSettings_Email = ({
  email,
  signalId,
  listEmails,
  sendStartOfDayNotifications,
  sendTradesNotifications,
  sendEndOfDayNotifications,
  sendRiskManagementNotifications,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();

  //region States

  const [emailsEdited, setEmailsEdited] = useState(listEmails.join(", "));
  const [
    sendStartOfDayNotificationsEdited,
    setSendStartOfDayNotificationsEdited,
  ] = useState(sendStartOfDayNotifications);
  const [sendTradesNotificationsEdited, setSendTradesNotificationsEdited] =
    useState(sendTradesNotifications);
  const [sendEndOfDayNotificationsEdited, setSendEndOfDayNotificationsEdited] =
    useState(sendEndOfDayNotifications);
  const [
    sendRiskManagementNotificationsEdited,
    setSendRiskManagementNotificationsEdited,
  ] = useState(sendRiskManagementNotifications);
  const [emailTestMessage, setEmailTestMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  //endregion

  //region Use Effects

  useEffect(() => {
    setEmailsEdited(listEmails.join(", "));
  }, [listEmails]);

  //endregion

  //region Methods

  const cancel = () => {
    onIsEditUpdate(false);
    setEmailsEdited(listEmails);
  };

  const emailsToList = (emails) => {
    if (!emails) {
      emails = emailsEdited;
    }
    return emails
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item.length > 0);
  };

  const handleInputChange_Emails = (settingName, settingNewValue) => {
    setEmailsEdited(settingNewValue);
    validateEmails(settingNewValue);
    setEmailTestMessage({ message: "", isError: false });
  };

  const handleInputChange_EndOfDay = (settingNewValue) => {
    setSendEndOfDayNotificationsEdited(settingNewValue);
  };

  const handleInputChange_StartOfDay = (settingNewValue) => {
    setSendStartOfDayNotificationsEdited(settingNewValue);
  };

  const handleInputChange_RiskManagement = (settingNewValue) => {
    setSendRiskManagementNotificationsEdited(settingNewValue);
  };

  const handleInputChange_Trades = (settingNewValue) => {
    setSendTradesNotificationsEdited(settingNewValue);
  };

  const isEmpty_Email = () => {
    return !emailsEdited | (emailsEdited.length === 0);
  };

  const save = () => {
    const listEmailsEdited = emailsToList();
    onSettingsChange(
      listEmailsEdited,
      sendStartOfDayNotificationsEdited,
      sendTradesNotificationsEdited,
      sendEndOfDayNotificationsEdited,
      sendRiskManagementNotificationsEdited
    );
    onIsEditUpdate(false);
  };

  const sendEmailTest = async () => {
    const listEmailsEdited = emailsToList();
    const isOk = await notifyEmailTest(email, signalId, listEmailsEdited);
    if (isOk) {
      setEmailTestMessage({
        message: "Test message sent successfully",
        isError: false,
      });
    } else {
      setEmailTestMessage({
        message: "Test failed. Please check your email address.",
        isError: true,
      });
    }
  };

  const validateEmails = (emails) => {
    const listEmails = emailsToList(emails);
    for (let i = 0; i < listEmails.length; i++) {
      const email = listEmails[i];
      if (!validateEmail(email)) {
        setErrorMessage("Email `" + email + "` is not valid.");
        return;
      }
    }
    setErrorMessage("");
  };

  //endregion

  //region Return

  return (
    <div className="table-settings-email-container">
      {isEdit && (
        <div className="setting-description">
          This will allow you to receive new trading signals via email. You can
          insert multiple email addresses, separated by a comma.
        </div>
      )}
      <div className="settings-fields">
        <Tooltip
          tooltipText={intl.formatMessage({
            id: "signalgenerator-settings-email-addresses",
          })}
          size="small"
        >
          <div
            className="setting-name"
            style={{ marginTop: isEdit ? "12px" : "0px" }}
          >
            {"Email addresses"}
          </div>
        </Tooltip>
        <div className="setting-input-value">
          {isEdit ? (
            <InputBox
              name={"Emails"}
              value={emailsEdited}
              nRows={1}
              maxLength={200}
              onUpdate={handleInputChange_Emails}
            />
          ) : isEmpty_Email() ? (
            "NotSet"
          ) : (
            emailsEdited
          )}
        </div>
      </div>
      {isEdit && errorMessage.length > 0 && (
        <div className="errors-container">{errorMessage}</div>
      )}
      <NotificationCheckboxGroup
        sendStartOfDayNotifications={sendStartOfDayNotificationsEdited}
        sendTradesNotifications={sendTradesNotificationsEdited}
        sendEndOfDayNotifications={sendEndOfDayNotificationsEdited}
        sendRiskManagementNotifications={sendRiskManagementNotificationsEdited}
        onStartOfDayNotificationsChange={handleInputChange_StartOfDay}
        onTradesNotificationsChange={handleInputChange_Trades}
        onEndOfDayNotificationsChange={handleInputChange_EndOfDay}
        onRiskManagementNotificationsChange={handleInputChange_RiskManagement}
        isEdit={isEdit}
      />
      {isEdit && (
        <>
          {/* Test */}
          <div className="setting-description">
            <button className="test-email-button" onClick={sendEmailTest}>
              Send test message
            </button>
            {emailTestMessage && (
              <span
                style={{
                  marginLeft: "10px",
                  color: emailTestMessage.isError ? "red" : "green",
                }}
              >
                {emailTestMessage.message}
              </span>
            )}
          </div>
        </>
      )}
      {isEdit && (
        <div className="button-container">
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={errorMessage.length > 0}
          />
        </div>
      )}
    </div>
  );

  //endregion
};
export default TableSettings_Email;
