/**
 * @since 2023-08-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { Table } from "antd";
import { useEffect, useState } from "react";
import "./TableGeneric.scss";

const TableGeneric = ({ itemData }) => {
  const [dataTable, setDataTable] = useState(null);

  // Create columns dynamically from itemData.Columns
  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
    },
    ...itemData.Columns.map((col, idx) => ({
      title: col,
      dataIndex: `value${idx}`,
      key: `value${idx}`,
    })),
  ];

  useEffect(() => {
    if (itemData === null) return;
    setDataTable(convertToAntTableData(itemData));
  }, [itemData]);

  function convertToAntTableData(data) {
    return data.Rows.map((row, rowIndex) => {
      let tableItem = { key: rowIndex, Name: row.Name };

      row.Values.forEach((value, valueIndex) => {
        tableItem[`value${valueIndex}`] = value;
      });

      return tableItem;
    });
  }

  return (
    <div className="table-generic" style={{ display: "inline-block" }}>
      <Table
        columns={columns}
        dataSource={dataTable}
        size="middle"
        pagination={false}
        rowClassName={"table-row"}
      />
    </div>
  );
};
export default TableGeneric;
