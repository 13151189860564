/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import MultipleChoiceBox from "components/Shared/MultipleChoiceBox";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { validateName } from "utils/validation";
import "./TableSettings.scss";

const TableSettings = ({
  dictPortfolios,
  settings,
  listSignalNames,
  nActiveSignals,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();

  const dictPortfolioIdToName = { ...invertDictionary(dictPortfolios), "": "" };

  function invertDictionary(dict) {
    let dictInverted = {};
    for (let key in dict) {
      if (dict.hasOwnProperty(key)) {
        dictInverted[dict[key]] = key;
      }
    }
    return dictInverted;
  }

  const getDefaultSettings = () => {
    return {
      Name: {
        type: "InputBox",
        fieldName: "SignalName",
        displayName: "Name",
        value: settings.SignalName,
        tooltip: intl.formatMessage({ id: "signalgenerator-settings-name" }),
      },
      Description: {
        type: "MultiLineInputBox",
        fieldName: "Description",
        displayName: "Description",
        value: settings.Description,
        displayValue: settings.Description,
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-description",
        }),
      },
      Portfolio: {
        type: "MultipleChoiceBox",
        fieldName: "PortfolioId",
        displayName: "Portfolio",
        value: dictPortfolioIdToName[settings.PortfolioId],
        choices: ["", ...Object.keys(dictPortfolios).sort()],
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-portfolio",
        }),
      },
      IsActive: {
        type: "CheckBox",
        fieldName: "IsActive",
        displayName: "Is Active",
        value: settings.IsActive ? "Yes" : "No",
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-isactive",
        }),
      },
      UseSlack: {
        type: "CheckBox",
        fieldName: "UseSlack",
        displayName: "Slack notifications",
        value: settings.UseSlack ? "Yes" : "No",
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-slack-enabled",
        }),
      },
      UseEmails: {
        type: "CheckBox",
        fieldName: "UseEmail",
        displayName: "Email notifications",
        value: settings.UseEmail ? "Yes" : "No",
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-emails-enabled",
        }),
      },
      UseTextMessage: {
        type: "CheckBox",
        fieldName: "UseTextMessage",
        displayName: "Text notifications",
        value: settings.UseTextMessage ? "Yes" : "No",
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-text-enabled",
        }),
      },
      UseApi: {
        type: "CheckBox",
        fieldName: "UseApi",
        displayName: "Real-time API",
        value: settings.UseApi ? "Yes" : "No",
        tooltip: intl.formatMessage({
          id: "signalgenerator-settings-api-enabled",
        }),
      },
    };
  };

  const [settingsFields, setSettingsFields] = useState(getDefaultSettings());
  const listLeftSettings = ["Name", "Portfolio", "IsActive", "Description"];
  const listRightSettings = [
    "UseEmails",
    "UseTextMessage",
    "UseSlack",
    "UseApi",
  ];
  const [listValidationErrors, setListValidationErrors] = useState([]);

  useEffect(() => {
    validateFields();
  }, [settingsFields]);

  const cancel = () => {
    setSettingsFields(getDefaultSettings());
    onIsEditUpdate(false);
  };

  const save = () => {
    const newSettings = { ...settings };
    for (const name in settingsFields) {
      const fieldName = settingsFields[name].fieldName;
      let fieldValue = settingsFields[name].value;
      if (fieldName === "PortfolioId") {
        // Convert portfolio name to id
        fieldValue = dictPortfolios[fieldValue] ?? "";
      } else if (settingsFields[name].type.includes("CheckBox")) {
        // Convert to boolean
        fieldValue = fieldValue === "Yes" ? true : false;
      }
      newSettings[fieldName] = fieldValue;
    }
    onSettingsChange(newSettings);
    onIsEditUpdate(false);
  };

  const handleInputChange = (settingName, settingNewValue) => {
    let newValue = String(settingNewValue);
    setSettingsFields({
      ...settingsFields,
      [settingName]: {
        ...settingsFields[settingName],
        value: newValue,
      },
    });
  };

  const renderErrors = () => {
    return (
      <div className="errors-container">
        {listValidationErrors.map((error) => (
          <div className="error">{error}</div>
        ))}
      </div>
    );
  };

  const renderWarnings = () => {
    return (
      <div className="warnings-container">
        <div className="warning">
          Imported strategies cannot be used in the live simulator.
        </div>
      </div>
    );
  };

  const renderSettings = (listSettings) => {
    return listSettings.map((name) => {
      // Adding the condition here
      if (
        name === "Daily Signal Time" &&
        settingsFields["Frequency"].value !== "Daily"
      ) {
        return null;
      }

      return (
        <div className="setting-item" key={`${name}-Outer`}>
          <div className="setting-row" key={`${name}-Main`}>
            <div
              className="setting-name"
              key={`${name}-Name`}
              style={{ marginTop: isEdit ? "12px" : "0px" }}
            >
              <Tooltip tooltipText={settingsFields[name].tooltip} size="small">
                {settingsFields[name].displayName}
              </Tooltip>
            </div>
            <div className="setting-value" key={`${name}-Value`}>
              {isEdit
                ? renderInputField(name, settingsFields[name])
                : settingsFields[name].value}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderInputField = (name, item) => {
    switch (item.type) {
      case "InputBox":
      case "Input.Dollar":
        return (
          <InputBox
            name={name}
            value={item.value}
            maxLength={30}
            onUpdate={handleInputChange}
          />
        );
      case "MultiLineInputBox":
        return (
          <InputBox
            name={name}
            value={item.value}
            nRows={7}
            maxLength={400}
            onUpdate={handleInputChange}
          />
        );
      case "MultipleChoiceBox":
        return (
          <MultipleChoiceBox
            name={name}
            choices={item.choices}
            value={item.value}
            onUpdate={handleInputChange}
          />
        );
      case "CheckBox":
        return (
          <MultipleChoiceBox
            name={name}
            choices={["Yes", "No"]}
            value={item.value}
            onUpdate={handleInputChange}
          />
        );
      case "CheckBoxDisabled":
        return (
          <MultipleChoiceBox
            name={name}
            choices={["No"]}
            value={item.value}
            onUpdate={handleInputChange}
          />
        );
      default:
        console.log(`Error: ${name} has unknown type ${item.type}`);
    }
  };

  const validateFields = () => {
    const listErrors = [];
    // Signal Name
    const signalName = String(settingsFields["Name"].value).trim();
    let { isValid, errorMessage } = validateName(signalName);
    if (!isValid) listErrors.push(`Signal Name: ${errorMessage}`);
    // Signal Name - Already Used
    if (
      signalName != settings.SignalName &&
      listSignalNames.includes(signalName)
    ) {
      listErrors.push(`Signal name already used.`);
    }
    // Portfolio
    const portfolioId = settingsFields["Portfolio"].value;
    if (!portfolioId) listErrors.push(`Portfolio not selected.`);
    // Is Active
    if (settingsFields["IsActive"].value === "Yes" && nActiveSignals > 2) {
      listErrors.push(
        `Cannot have more than 2 active signals per account. Please contact support if you need more.`
      );
    }
    setListValidationErrors(listErrors);
  };

  return (
    <div className="table-settings-signals">
      <div className="table-settings-container">
        <div className="table-settings-left">
          {/* Left side of the table */ renderSettings(listLeftSettings, true)}
          {/* Warnings */ isEdit && renderWarnings()}
          {/* Errors */ isEdit && renderErrors()}
        </div>
        <div className="table-settings-right">
          {
            /* Right side of the table */ renderSettings(
              listRightSettings,
              false
            )
          }
        </div>
      </div>
      {isEdit && (
        <BarSaveCancel
          onCancel={cancel}
          onSave={save}
          isSaveDisabled={listValidationErrors.length > 0}
        />
      )}
    </div>
  );
};

export default TableSettings;
