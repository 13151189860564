/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Tooltip from "components/Shared/Tooltip";
import _ from "lodash";
import { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import Indicator from "../Indicator";
import IndicatorSelector from "../IndicatorSelector";
import "./Rule.scss";

const Rule = ({
  ruleIndex,
  ruleSettings,
  operators,
  indicators,
  isEdit,
  onRuleChanged,
  onRuleDeleted,
  onValidationErrorsUpdate,
}) => {
  const [indicatorsRight, setIndicatorsRight] = useState(indicators);
  const [isLeftOnly, setIsLeftOnly] = useState(true);

  useEffect(() => {
    setIndicatorsRight(
      Object.fromEntries(
        Object.entries(indicators).filter(
          ([key, indicator]) => !indicator.IsLeftOnly
        )
      )
    );
  }, [indicators]);

  useEffect(() => {
    // Validate
    const validateErrors = async () => {
      const listErrors = await getValidationErrors(ruleSettings, ruleIndex);
      await onValidationErrorsUpdate(ruleSettings.RuleId, listErrors);
    };
    validateErrors();
    // Check if left only
    let isLeftOnly = false;
    if (ruleSettings.Left) {
      isLeftOnly = indicators?.[ruleSettings.Left?.IndicatorName]?.IsLeftOnly;
    }
    setIsLeftOnly(isLeftOnly);
  }, [ruleIndex, ruleSettings, indicators]);

  const cloneIndicator = (indicator) => {
    const indicatorCopy = _.cloneDeep(indicator);
    return {
      ...indicatorCopy,
      Parameters: indicatorCopy.Parameters.map((param) => ({
        ...param,
        ParameterId: uuidv4(),
      })),
    };
  };

  const handleIndicatorChange = async (indicatorIndex, newIndicator) => {
    const newIndicatorCopy = cloneIndicator(newIndicator);
    let newRuleSettings = { ...ruleSettings };
    switch (indicatorIndex) {
      case "Left":
        newRuleSettings.Left = newIndicatorCopy;
        if (newIndicator.IsLeftOnly) {
          newRuleSettings.Operator = null;
          newRuleSettings.Right = null;
        }
        break;
      case "Operator":
        newRuleSettings.Operator = newIndicatorCopy;
        break;
      case "Right":
        newRuleSettings.Right = newIndicatorCopy;
        break;
      default:
        console.log("Error: indicator not recognized: " + indicatorIndex);
    }
    const listErrors = await getValidationErrors(newRuleSettings);
    onRuleChanged(ruleIndex, newRuleSettings, listErrors);
  };

  const handleRuleDeleted = async () => {
    onRuleDeleted(ruleIndex, ruleSettings.RuleId);
  };

  const getValidationErrors = async (newRuleSettings, newRuleIndex) => {
    if (!newRuleSettings) newRuleSettings = ruleSettings;
    if (!newRuleIndex) newRuleIndex = ruleIndex;
    const listErrors = [];
    if (!newRuleSettings.Left?.IndicatorName) {
      listErrors.push(`Rule ${newRuleIndex + 1}: Missing left indicator.`);
    }
    if (newRuleSettings.Operator) {
      if (!newRuleSettings.Operator?.IndicatorName) {
        listErrors.push(`Rule ${newRuleIndex + 1}: Missing operator.`);
      }
      if (!newRuleSettings.Right?.IndicatorName) {
        listErrors.push(`Rule ${newRuleIndex + 1}: Missing right indicator.`);
      }
    }
    return listErrors;
  };

  return (
    <div className="rule-container">
      <div className="entry-table">
        {/* Left Indicator */}
        {ruleSettings?.Left?.Parameters && (
          <div
            className={`indicator-container ${isLeftOnly ? "full-width" : ""}`}
          >
            {/* Indicator Selector */}
            {!isEdit ? (
              <div className="indicator-selector indicator-selector-text">
                <Tooltip
                  tooltipText={
                    indicators[ruleSettings?.Left?.IndicatorName]?.Description
                  }
                >
                  {indicators[ruleSettings?.Left?.IndicatorName]?.DisplayName}
                </Tooltip>
              </div>
            ) : (
              <IndicatorSelector
                index="Left"
                indicatorIndex="Left"
                currentValue={ruleSettings?.Left?.IndicatorName}
                indicators={indicators}
                onIndicatorSelected={handleIndicatorChange}
              />
            )}
            {/* Left Indicator */}
            <Indicator
              index="Left"
              indicatorIndex="Left"
              indicatorSettings={ruleSettings.Left}
              indicatorInfo={indicators[ruleSettings?.Left?.IndicatorName]}
              isEdit={isEdit}
              onIndicatorChange={handleIndicatorChange}
            />
          </div>
        )}
        {!isLeftOnly ? (
          <>
            <div className="operator-container">
              {!isEdit ? (
                <div className="indicator-selector indicator-selector-text">
                  <Tooltip
                    tooltipText={
                      operators[ruleSettings?.Operator?.IndicatorName]
                        ?.Description
                    }
                  >
                    {
                      operators[ruleSettings?.Operator?.IndicatorName]
                        ?.DisplayName
                    }
                  </Tooltip>
                </div>
              ) : (
                <IndicatorSelector
                  index="Operator"
                  indicatorIndex="Operator"
                  currentValue={ruleSettings?.Operator?.IndicatorName}
                  indicators={operators}
                  onIndicatorSelected={handleIndicatorChange}
                />
              )}
            </div>
            <div className="indicator-container">
              {!isEdit ? (
                <div className="indicator-selector indicator-selector-text">
                  <Tooltip
                    tooltipText={
                      indicators[ruleSettings?.Right?.IndicatorName]
                        ?.Description
                    }
                  >
                    {
                      indicators[ruleSettings?.Right?.IndicatorName]
                        ?.DisplayName
                    }
                  </Tooltip>
                </div>
              ) : (
                <IndicatorSelector
                  index="Right"
                  indicatorIndex="Right"
                  currentValue={ruleSettings?.Right?.IndicatorName}
                  indicators={indicatorsRight}
                  onIndicatorSelected={handleIndicatorChange}
                />
              )}
              {/* Right Indicator */}
              <Indicator
                index="Right"
                indicatorIndex="Right"
                indicatorSettings={ruleSettings.Right}
                indicatorInfo={indicators[ruleSettings?.Right?.IndicatorName]}
                isEdit={isEdit}
                onIndicatorChange={handleIndicatorChange}
              />
            </div>
          </>
        ) : (
          <></>
        )}
        {
          /* Delete button */ isEdit && (
            <div className="delete-button">
              {<IoMdClose className="close-icon" onClick={handleRuleDeleted} />}
            </div>
          )
        }
      </div>
    </div>
  );
};

export default Rule;
