/**
 * @since 2023-04-15
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import PanelAnalysis from "./PanelAnalysis";

export default PanelAnalysis;
