/**
 * @since 2023-12-22
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ItemIndicatorStats from "./ItemIndicatorStats";

export default ItemIndicatorStats;
