/**
 * @since 2023-08-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableSettings_Slack from "./TableSettings_Slack";

export default TableSettings_Slack;
