/**
 * @since 2023-08-07
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableMonthlyReturns from "components/Shared/TableMonthlyReturns";

const ItemTableReturnsByMonth = ({ itemData }) => {
  return <TableMonthlyReturns isLoading={false} data={itemData} />;
};

export default ItemTableReturnsByMonth;
