/**
 * @since 2023-08-02
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableReturns from "./TableReturns.tsx";

export default TableReturns;
