/**
 * @since 2023-10-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableSettings_Email from "./TableSettings_Email";

export default TableSettings_Email;
