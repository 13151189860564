/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import InputBox from "components/Shared/InputBox";
import Tooltip from "components/Shared/Tooltip";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toInteger, toPercentage } from "utils/formatting";
import { validateInteger, validatePercentage } from "utils/validation";
import "./TableRiskManagement.scss";

const TableRiskManagement = ({
  settings,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();

  const getDefaultSettings = () => {
    return {
      "Instrument Notional": {
        displayName: "Notional",
        fieldName: "Instrument_Notional",
        value: settings.RiskManagement.Instrument_Notional,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-notional",
        }),
      },
      "Instrument Volatility": {
        displayName: "Volatility",
        fieldName: "Instrument_Volatility",
        value: settings.RiskManagement.Instrument_Volatility,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-volatility",
        }),
      },
      "Instrument Stop Loss": {
        displayName: "Stop Loss",
        fieldName: "Instrument_StopLoss",
        value: settings.RiskManagement.Instrument_StopLoss,
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-stoploss",
        }),
      },
      "Instrument Grace Period": {
        displayName: "Grace Period",
        fieldName: "Instrument_GracePeriod",
        value: settings.RiskManagement.Instrument_GracePeriod,
        linkedTo: "Instrument Stop Loss",
        tooltip: intl.formatMessage({
          id: "riskmanagement-instrument-graceperiod",
        }),
      },
      "Sector Notional (Gross)": {
        displayName: "Notional (Gross)",
        fieldName: "Sector_Notional_Gross",
        value: settings.RiskManagement.Sector_Notional_Gross,
        tooltip: intl.formatMessage({
          id: "riskmanagement-sector-notionalgross",
        }),
      },
      "Sector Notional (Net)": {
        displayName: "Notional (Net)",
        fieldName: "Sector_Notional_Net",
        value: settings.RiskManagement.Sector_Notional_Net,
        tooltip: intl.formatMessage({
          id: "riskmanagement-sector-notionalnet",
        }),
      },
      "Sector Volatility": {
        displayName: "Volatility",
        fieldName: "Sector_Volatility",
        value: settings.RiskManagement.Sector_Volatility,
        tooltip: intl.formatMessage({ id: "riskmanagement-sector-volatility" }),
      },
      "Sector Stop Loss": {
        displayName: "Stop Loss",
        fieldName: "Sector_StopLoss",
        value: settings.RiskManagement.Sector_StopLoss,
        tooltip: intl.formatMessage({ id: "riskmanagement-sector-stoploss" }),
      },
      "Sector Grace Period": {
        displayName: "Grace Period",
        fieldName: "Sector_GracePeriod",
        value: settings.RiskManagement.Sector_GracePeriod,
        tooltip: intl.formatMessage({
          id: "riskmanagement-sector-graceperiod",
        }),
        linkedTo: "Sector Stop Loss",
      },
      "Strategy Notional (Gross)": {
        displayName: "Notional (Gross)",
        fieldName: "Strategy_Notional_Gross",
        value: settings.RiskManagement.Strategy_Notional_Gross,
        tooltip: intl.formatMessage({
          id: "riskmanagement-strategy-notionalgross",
        }),
      },
      "Strategy Notional (Net)": {
        displayName: "Notional (Net)",
        fieldName: "Strategy_Notional_Net",
        value: settings.RiskManagement.Strategy_Notional_Net,
        tooltip: intl.formatMessage({
          id: "riskmanagement-strategy-notionalnet",
        }),
      },
      "Strategy Volatility": {
        displayName: "Volatility",
        fieldName: "Strategy_Volatility",
        value: settings.RiskManagement.Strategy_Volatility,
        tooltip: intl.formatMessage({
          id: "riskmanagement-strategy-volatility",
        }),
      },
      "Strategy Stop Loss": {
        displayName: "Stop Loss",
        fieldName: "Strategy_StopLoss",
        value: settings.RiskManagement.Strategy_StopLoss,
        tooltip: intl.formatMessage({ id: "riskmanagement-strategy-stoploss" }),
      },
      "Strategy Grace Period": {
        displayName: "Grace Period",
        fieldName: "Strategy_GracePeriod",
        value: settings.RiskManagement.Strategy_GracePeriod,
        tooltip: intl.formatMessage({
          id: "riskmanagement-strategy-graceperiod",
        }),
        linkedTo: "Strategy Stop Loss",
      },
      "Portfolio Notional (Gross)": {
        displayName: "Notional (Gross)",
        fieldName: "Portfolio_Notional_Gross",
        value: settings.RiskManagement.Portfolio_Notional_Gross,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-notionalgross",
        }),
      },
      "Portfolio Notional (Net)": {
        displayName: "Notional (Net)",
        fieldName: "Portfolio_Notional_Net",
        value: settings.RiskManagement.Portfolio_Notional_Net,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-notionalnet",
        }),
      },
      "Portfolio Volatility": {
        displayName: "Volatility",
        fieldName: "Portfolio_Volatility",
        value: settings.RiskManagement.Portfolio_Volatility,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-volatility",
        }),
      },
      "Portfolio Stop Loss": {
        displayName: "Stop Loss",
        fieldName: "Portfolio_StopLoss",
        value: settings.RiskManagement.Portfolio_StopLoss,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-stoploss",
        }),
      },
      "Portfolio Grace Period": {
        displayName: "Grace Period",
        fieldName: "Portfolio_GracePeriod",
        value: settings.RiskManagement.Portfolio_GracePeriod,
        tooltip: intl.formatMessage({
          id: "riskmanagement-portfolio-graceperiod",
        }),
        linkedTo: "Portfolio Stop Loss",
      },
    };
  };

  const [riskManagementSettings, setRiskManagementSettings] = useState(
    getDefaultSettings()
  );
  const listInstrumentSettings = [
    "Instrument Notional",
    "Instrument Volatility",
    "Instrument Stop Loss",
    "Instrument Grace Period",
  ];
  const listSectorSettings = [
    "Sector Notional (Gross)",
    "Sector Notional (Net)",
    "Sector Volatility",
    "Sector Stop Loss",
    "Sector Grace Period",
  ];
  const listStrategySettings = [
    "Strategy Notional (Gross)",
    "Strategy Notional (Net)",
    "Strategy Volatility",
    "Strategy Stop Loss",
    "Strategy Grace Period",
  ];
  const listPortfolioSettings = [
    "Portfolio Notional (Gross)",
    "Portfolio Notional (Net)",
    "Portfolio Volatility",
    "Portfolio Stop Loss",
    "Portfolio Grace Period",
  ];

  const [listValidationErrors, setListValidationErrors] = useState([]);

  useEffect(() => {
    // Validate
    const listErrors = [];
    for (const name in riskManagementSettings) {
      if (name.includes("Grace Period")) {
        const { isValid, errorMessage } = validateInteger(
          riskManagementSettings[name].value,
          true
        );
        if (!isValid) listErrors.push(`${name} ${errorMessage}`);
      } else {
        const { isValid, errorMessage } = validatePercentage(
          riskManagementSettings[name].value,
          true
        );
        if (!isValid) listErrors.push(`${name} ${errorMessage}`);
      }
    }
    setListValidationErrors(listErrors);
  }, [riskManagementSettings]);

  const cancel = () => {
    setRiskManagementSettings(getDefaultSettings());
    onIsEditUpdate(false);
  };

  const save = () => {
    // Update global settings
    const newRiskManagement = { ...settings.RiskManagement };
    for (const name in riskManagementSettings) {
      const field = riskManagementSettings[name]["fieldName"];
      newRiskManagement[field] = riskManagementSettings[name]["value"];
    }
    // Save
    onSettingsChange(newRiskManagement);
    onIsEditUpdate(false);
  };

  const handleInputChange = (settingName, riskManagementNewValue) => {
    setRiskManagementSettings({
      ...riskManagementSettings,
      [settingName]: {
        ...riskManagementSettings[settingName],
        value: riskManagementNewValue,
      },
    });
  };

  const renderErrors = () => {
    return (
      <div className="errors-container">
        {listValidationErrors.map((error) => (
          <div className="error">{error}</div>
        ))}
      </div>
    );
  };

  const renderSettingsGroup = (listSettings) => {
    return listSettings.map((name) => {
      const isGracePeriod = name.includes("Grace Period");
      const shouldDisplay =
        isGracePeriod &&
        riskManagementSettings[riskManagementSettings[name].linkedTo].value !==
          "";
      return isGracePeriod
        ? shouldDisplay
          ? renderSettingInteger(name)
          : null
        : renderSettingPercentage(name);
    });
  };

  const renderSettingInteger = (name) => {
    return (
      <div className="portfolio-riskmanagement-item" key={`${name}.Outer`}>
        <div className="portfolio-riskmanagement-row" key={`${name}.Main`}>
          <div
            className="portfolio-riskmanagement-name"
            key={`${name}.Name`}
            style={{ marginTop: isEdit ? "12px" : "0px" }}
          >
            <Tooltip
              tooltipText={riskManagementSettings[name].tooltip}
              size="small"
            >
              {riskManagementSettings[name].displayName}
            </Tooltip>
          </div>
          <div className="portfolio-riskmanagement-value" key={`${name}.Value`}>
            {isEdit ? (
              <InputBox
                name={name}
                value={riskManagementSettings[name].value}
                maxLength={10}
                onUpdate={handleInputChange}
              />
            ) : (
              toInteger(riskManagementSettings[name].value)
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderSettingPercentage = (name) => {
    return (
      <div className="portfolio-riskmanagement-item" key={`${name}.Outer`}>
        <div className="portfolio-riskmanagement-row" key={`${name}.Main`}>
          <div
            className="portfolio-riskmanagement-name"
            key={`${name}.Name`}
            style={{ marginTop: isEdit ? "12px" : "0px" }}
          >
            <Tooltip
              tooltipText={riskManagementSettings[name].tooltip}
              size="small"
            >
              {riskManagementSettings[name].displayName}
            </Tooltip>
          </div>
          <div className="portfolio-riskmanagement-value" key={`${name}.Value`}>
            {isEdit ? (
              <InputBox
                name={name}
                value={riskManagementSettings[name].value}
                maxLength={10}
                onUpdate={handleInputChange}
              />
            ) : (
              toPercentage(riskManagementSettings[name].value)
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="risk-management-portfolio">
      <div className="table-riskmanagement-container">
        <div className="settings-group-container">
          <div className="settings-group-title">INSTRUMENT</div>
          {renderSettingsGroup(listInstrumentSettings)}
        </div>
        <div className="settings-group-container">
          <div className="settings-group-title">SECTOR</div>
          {renderSettingsGroup(listSectorSettings)}
        </div>
        <div className="settings-group-container">
          <div className="settings-group-title">STRATEGY</div>
          {renderSettingsGroup(listStrategySettings)}
        </div>
        <div className="settings-group-container">
          <div className="settings-group-title">PORTFOLIO</div>
          {renderSettingsGroup(listPortfolioSettings)}
        </div>
      </div>
      {isEdit && renderErrors()}
      {isEdit && (
        <BarSaveCancel
          onCancel={cancel}
          onSave={save}
          isSaveDisabled={listValidationErrors.length > 0}
        />
      )}
    </div>
  );
};
export default TableRiskManagement;
