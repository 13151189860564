/**
 * @since 2023-09-27
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";

import Tooltip from "components/Shared/Tooltip";
import { createApiKey } from "dataHandling/signal";
import { useState } from "react";
import { useIntl } from "react-intl";
import "./TableSettings_Api.scss";

const TableSettings_Api = ({
  signalId,
  apiKey,
  onSettingsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const intl = useIntl();
  const [apiKeyEdited, setApiKeyEdited] = useState(apiKey);

  const cancel = () => {
    onIsEditUpdate(false);
    setApiKeyEdited(apiKey);
  };

  const save = () => {
    onSettingsChange(apiKeyEdited);
    onIsEditUpdate(false);
  };

  const refreshKey = async () => {
    const apiKeyNew = await createApiKey(signalId);
    setApiKeyEdited(apiKeyNew);
  };

  return (
    <div className="table-settings-api-container">
      {isEdit && (
        <div className="setting-description">
          This will allow you to receive new trading signals in your custom
          applications.
          <br />
          <br />
          We recommend to refresh it once per quarter.
          <br />
        </div>
      )}
      <div className="settings-fields">
        <Tooltip
          tooltipText={intl.formatMessage({
            id: "signalgenerator-settings-api-key",
          })}
          size="small"
        >
          <div
            className="setting-name"
            style={{ marginTop: isEdit ? "10px" : "0px" }}
          >
            {"API Key"}
          </div>
        </Tooltip>
        {isEdit ? (
          <div className="settings-container">
            <div className="setting-value">{apiKeyEdited}</div>
            <button className="refresh-button" onClick={refreshKey}>
              refresh
            </button>
          </div>
        ) : (
          <div className="setting-value">
            {apiKeyEdited ? apiKeyEdited : "Not set"}
          </div>
        )}
      </div>
      {isEdit && (
        <div className="button-container">
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={false}
          />
        </div>
      )}
    </div>
  );
};
export default TableSettings_Api;
