/**
 * @since 2023-08-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TableGeneric from "./TableGeneric";

export default TableGeneric;
