/**
 * @since 2023-12-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import TimeRangeSelector from "./TimeRangeSelector";

export default TimeRangeSelector;
