/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import Tooltip from "components/Shared/Tooltip";
import ParameterSelector from "../ParameterSelector";

const Parameter = ({
  parameterIndex,
  parameterSettings,
  parameterInfo,
  isEdit,
  onParameterChange,
}) => {
  const handleParameterChange = (parameterIndex, newValues, newValue) => {
    const newParameterSettings = {
      ...parameterSettings,
      Values: newValues,
      Value: newValue,
    };
    onParameterChange(parameterIndex, newParameterSettings);
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          height: !isEdit ? "50px" : "auto",
          width: "100%",
          padding: !isEdit ? "15px 20px" : "15px 0px 15px 20px",
          fontSize: "16px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.05)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: !isEdit ? "row" : "column",
          }}
        >
          <div
            style={{
              color: "rgba(255, 255, 255, 0.5)",
              marginBottom: !isEdit ? "0" : "10px",
            }}
          >
            <Tooltip tooltipText={parameterInfo?.Description}>
              {parameterInfo?.DisplayName}
            </Tooltip>
          </div>
          {!isEdit ? (
            <div style={{ display: "flex", color: "rgba(255, 255, 255, 0.5)" }}>
              {parameterSettings?.Values?.map((item, index) => {
                return (
                  <div key={index}>
                    <span
                      style={{
                        color:
                          parameterSettings.Value === item
                            ? "white"
                            : "rgba(255, 255, 255, 0.5)",
                      }}
                    >
                      {" "}
                      {item}
                    </span>
                    {index !== parameterSettings?.Values?.length - 1 && ", "}
                  </div>
                );
              })}
            </div>
          ) : (
            <ParameterSelector
              parameterIndex={parameterIndex}
              parameterType={parameterSettings?.ParameterType}
              values={parameterSettings?.Values}
              value={parameterSettings?.Value}
              allValues={parameterInfo?.AllValues}
              minValue={parameterInfo?.MinValue}
              maxValue={parameterInfo?.MaxValue}
              onValueChange={handleParameterChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Parameter;
