/**
 * @since 2023-08-08
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ItemChartSimStats from "./ItemChartSimStats";

export default ItemChartSimStats;
