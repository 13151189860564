/**
 * @since 2023-04-09
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import PanelHeader from "components/Shared/PanelHeader";
import ToggleButtonGroupResearch from "components/Shared/ToggleButtonGroupResearch";
import {
  loadPortfolioSettings,
  savePortfolioSettings,
} from "dataHandling/portfolio";
import { useEffect, useState } from "react";
import { FiPieChart } from "react-icons/fi";
import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom";
import PanelAnalysis from "./PanelAnalysis";
import PanelReports from "./PanelReports";
import PanelScores from "./PanelScores";
import PanelSettings from "./PanelSettings";

const PortfolioBuilder = ({
  email,
  menuItem,
  listPortfolioNames,
  listLockedIds,
  configuration,
  dictStrategies,
  dictImported,
  dictPremium,
  onPortfolioNameChange,
  isNavBarVisible,
}) => {
  const [portfolioId, setPortfolioId] = useState();
  const [portfolioSettings, setPortfolioSettings] = useState();
  const [containsOnlyStrategies, setContainsOnlyStrategies] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!menuItem) return;
    setPortfolioId(menuItem.ItemId);
  }, [menuItem]);

  useEffect(() => {
    (async () => {
      if (!portfolioId) return;
      const newPortfolioSettings = await loadPortfolioSettings(
        email,
        portfolioId
      );
      await updatePortfolioSettings(newPortfolioSettings);
    })();
  }, [portfolioId]);

  //region Methods

  const handlePortfolioSettingsChange = async (newPortfolioSettings) => {
    const oldPortfolioName = portfolioSettings.Settings.PorfolioName;
    const newPortfolioName = newPortfolioSettings.Settings.PortfolioName;
    await savePortfolioSettings(email, portfolioId, newPortfolioSettings);
    if (oldPortfolioName !== newPortfolioName) {
      onPortfolioNameChange(oldPortfolioName, newPortfolioName);
    }
    await updatePortfolioSettings(newPortfolioSettings);
  };

  const goToAnalysisView = () => {
    navigate("analysis", { relative: true });
  };

  const goToReportsView = () => {
    navigate("reports", { relative: true });
  };

  const goToScoresView = () => {
    navigate("scores", { relative: true });
  };

  const updatePortfolioSettings = async (newPortfolioSettings) => {
    setPortfolioSettings(newPortfolioSettings);
    const newContainsOnlyStrategies =
      Object.keys(newPortfolioSettings.Allocations).length > 0 &&
      Object.values(newPortfolioSettings.Allocations).every(
        (allocation) => allocation.AllocationType === "Strategy"
      );
    setContainsOnlyStrategies(newContainsOnlyStrategies);
  };

  //endregion

  return (
    <>
      {menuItem && (
        <>
          <PanelHeader
            menuItem={menuItem}
            icon={<FiPieChart style={{ fontSize: "30px" }} />}
            toggleButtonGroup={
              <ToggleButtonGroupResearch
                selectedValue={menuItem.ItemView}
                strategyType="portfolio"
              />
            }
            isNavBarVisible={isNavBarVisible}
          />
          <Routes>
            <Route element={<Outlet />}>
              <Route
                path={"settings"}
                element={
                  <PanelSettings
                    email={email}
                    portfolioSettings={portfolioSettings}
                    containsOnlyStrategies={containsOnlyStrategies}
                    listPortfolioNames={listPortfolioNames}
                    isLocked={listLockedIds.includes(menuItem.ItemId)}
                    configuration={configuration}
                    dictStrategies={dictStrategies}
                    dictImported={dictImported}
                    dictPremium={dictPremium}
                    onPortfolioSettingsChange={handlePortfolioSettingsChange}
                    onAnalysisClick={goToAnalysisView}
                  />
                }
              />
              <Route
                path={"analysis"}
                element={
                  <PanelAnalysis
                    email={email}
                    portfolioId={portfolioId}
                    portfolioName={menuItem?.ItemName}
                    containsOnlyStrategies={containsOnlyStrategies}
                    onScoresClick={goToScoresView}
                  />
                }
              />
              <Route
                path={"reports"}
                element={
                  <PanelReports email={email} portfolioId={portfolioId} />
                }
              />
              <Route
                path={"scores"}
                element={
                  <PanelScores
                    email={email}
                    portfolioId={portfolioId}
                    onReportsClick={goToReportsView}
                  />
                }
              />
              <Route path="*" element={<Navigate to="settings" />} />
            </Route>
          </Routes>
        </>
      )}
    </>
  );
};
export default PortfolioBuilder;
