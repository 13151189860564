/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { IoIosArrowDropdown, IoIosArrowDropup } from "react-icons/io";
import "./EditableSection.scss";

const EditableSection = ({
  children,
  title,
  isOpened,
  canEdit,
  isEdit,
  updateIsEdit,
}) => {
  const [newIsOpened, setNewIsOpened] = useState(isOpened);

  return (
    <div className={`editable-section ${isEdit ? "edit-mode" : ""}`}>
      <div className="editable-head">
        <div className="icon-wrapper">
          <div className="icon-wrapper">
            {newIsOpened ? (
              <IoIosArrowDropup onClick={() => setNewIsOpened(!newIsOpened)} />
            ) : (
              <IoIosArrowDropdown
                onClick={() => setNewIsOpened(!newIsOpened)}
              />
            )}
            <span className="title-text"> {title} </span>
          </div>
        </div>
        {newIsOpened && canEdit && !isEdit && (
          <div className="edit-tools">
            <BiPencil
              className="edit-icon"
              onClick={() => updateIsEdit(true)}
            />
            <div className="edit-text" onClick={() => updateIsEdit(true)}>
              Edit
            </div>
          </div>
        )}
      </div>
      {newIsOpened && <div className="editable-content">{children}</div>}
    </div>
  );
};

export default EditableSection;
