/**
 * @since: 2023-10-04
 * @author: Mohammad Traboulsi
 * @maintainer: Francesco Parrella
 * @copyright: AlgoTraders, All rights reserved
 */

// Name of the dictionary containing the list of itemIds / itemNames for each category:
// e.g. menuItems.Strategies["Strategy 01"] = "STR-20220101-12399213"
export const MENU_ITEMS = {
  STRATEGY: "Strategies",
  PREMIUM: "Premium",
  PORTFOLIO: "Portfolios",
  IMPORTED: "Imported",
  SIGNAL: "Signals",
};

// Default view for each category.
export const VIEW_DEFAULT = {
  STRATEGY: "Settings",
  PREMIUM: "Settings",
  PORTFOLIO: "Settings",
  IMPORTED: "Settings",
  SIGNAL: "Settings",
};
