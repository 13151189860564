/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import "./TextWait.scss";

const TextWait = () => {
  return <div className="text-wait">Processing, please wait...</div>;
};

export default TextWait;
