/**
 * @since 2023-04-13
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import BarSaveCancel from "components/Shared/BarSaveCancel";
import { useEffect, useState } from "react";
import Spread from "./Spread";
import "./TableSpreads.scss";

const TableSpreads = ({
  email,
  strategySettings,
  universeSettings,
  dictInstruments,
  onSpreadsChange,
  isEdit,
  onIsEditUpdate,
}) => {
  const [dictValidationErrors, setDictValidationErrors] = useState({});
  const [isValidationOk, setIsValidationOk] = useState(true);
  const [listSpreadSettings, setListSpreadSettings] = useState(
    strategySettings?.Spreads || []
  );

  //region Use Effects

  useEffect(() => {
    const newIsValidationOk = Object.values(dictValidationErrors).every(
      (value) => Array.isArray(value) && value.length === 0
    );
    setIsValidationOk(newIsValidationOk);
  }, [dictValidationErrors]);

  useEffect(() => {
    setListSpreadSettings(strategySettings?.Spreads || []);
  }, [strategySettings]);

  //endregion

  const cancel = () => {
    setListSpreadSettings(strategySettings?.Spreads || []);
    setDictValidationErrors({});
    onIsEditUpdate(false);
  };

  const handleSpreadUpdate = async (spreadIndex, spreadSettingsEdited) => {
    const newSpreadSettings = [
      ...listSpreadSettings.slice(0, spreadIndex),
      spreadSettingsEdited,
      ...listSpreadSettings.slice(spreadIndex + 1),
    ];
    setListSpreadSettings(newSpreadSettings);
  };

  const handleValidationErrors = async (spreadId, listErrors) => {
    setDictValidationErrors((prevDictValidationErrors) => {
      const newDictValidationErrors = {
        ...prevDictValidationErrors,
        [spreadId]: listErrors,
      };
      if (!listErrors || listErrors.length === 0) {
        delete newDictValidationErrors[spreadId];
      }
      return newDictValidationErrors;
    });
  };

  const renderErrors = () => (
    <div className="errors-container">
      {Object.entries(dictValidationErrors).map(([key, listErrors]) =>
        listErrors.map((error) => (
          <div className="error" key={`${key}-${error}`}>
            {error}
          </div>
        ))
      )}
    </div>
  );

  const save = () => {
    onSpreadsChange(listSpreadSettings);
    onIsEditUpdate(false);
  };

  return (
    <div className="table-spreads">
      <div className="spreads-container">
        {listSpreadSettings.map((spreadSettings, index) => (
          <Spread
            key={index}
            spreadId={index}
            spreadSettings={spreadSettings}
            isEdit={isEdit}
            universeSettings={universeSettings}
            dictInstruments={dictInstruments}
            onUpdate={handleSpreadUpdate}
            onValidationErrorsUpdate={handleValidationErrors}
          />
        ))}
      </div>
      {isEdit && renderErrors()}
      {isEdit && (
        <div className="cancel-save-bar">
          <BarSaveCancel
            onCancel={cancel}
            onSave={save}
            isSaveDisabled={!isValidationOk}
          />
        </div>
      )}
    </div>
  );
};
export default TableSpreads;
