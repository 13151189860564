/**
 * @since 2024-05-26
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ItemTablePerformance from "./ItemTablePerformance";

export default ItemTablePerformance;
