/**
 * @since 2023-04-19
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import { toInteger } from "./formatting";

function strictParseFloat(str) {
  // This regex matches a floating-point number which may start with a minus sign,
  // followed by zero or more digits, optionally followed by a dot and one or more digits.
  // It does not allow trailing characters that are not part of the number.
  if (/^-?\d*(\.\d+)?$/.test(str)) {
    return parseFloat(str);
  }
  return NaN;
}

function strictParseInt(str) {
  // This regex matches an integer, which may start with a minus sign,
  // followed by one or more digits, and does not allow trailing characters.
  if (/^-?\d+$/.test(str)) {
    return parseInt(str, 10); // Always specify the radix, 10 for decimal.
  }
  return NaN;
}

function validateAmount(amount, minValue = 100000, maxValue = 1e8) {
  // Remove initial '$' if present
  amount = String(amount).replace(/^\$/, "");

  // Remove thousand separators
  amount = amount.replace(/,/g, "");

  // Check if the amount is a valid number
  const amountNumber = strictParseFloat(amount);
  if (isNaN(amountNumber)) {
    return { isValid: false, errorMessage: "Invalid number" };
  }

  // Check for decimals
  if (!Number.isInteger(amountNumber)) {
    return { isValid: false, errorMessage: "Decimals are not allowed" };
  }

  // Check for negative numbers or 0
  if (amountNumber < minValue) {
    return {
      isValid: false,
      errorMessage: "should be greater than " + toInteger(minValue),
    };
  }

  // Check for negative numbers or 0
  if (amountNumber > maxValue) {
    return {
      isValid: false,
      errorMessage: "should be lower than " + toInteger(maxValue),
    };
  }

  return { isValid: true };
}

function validateDate(dateString, startDateString = null) {
  // If empty string, it's valid
  if (!dateString || dateString === "null") {
    return { isValid: true, errorMessage: "" };
  }

  // Try to create a date object
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return {
      isValid: false,
      errorMessage: "is not a valid date",
    };
  }

  // Regex for YYYY/MM/DD and YYYY-MM-DD formats
  const regex = /^(?:\d{4}[-/]\d{2}[-/]\d{2})$/;
  if (!regex.test(dateString)) {
    return { isValid: false, errorMessage: "format should by YYYY/MM/DD" };
  }

  // Check if endDate > startDate + 100 days
  if (startDateString) {
    const dateStart = new Date(startDateString);
    if (!isNaN(dateStart.getTime())) {
      const differenceInMilliseconds = date.getTime() - dateStart.getTime();
      const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);
      if (differenceInDays < 100)
        return {
          isValid: false,
          errorMessage: "should be at least 100 days after the start date",
        };
    }
  }

  // Looks good
  return { isValid: true, errorMessage: "" };
}

function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

function validateInteger(
  amount,
  allowEmpty = false,
  minValue = 0,
  maxValue = 1000
) {
  // Remove thousand separators
  amount = String(amount).replace(/,/g, "");

  // check if empty
  if (allowEmpty && (!amount || amount === "")) {
    return {
      isValid: true,
      errorMessage: "",
    };
  }

  // Check if the amount is a valid number
  const amountNumber = strictParseInt(amount);
  if (isNaN(amountNumber)) {
    return { isValid: false, errorMessage: "Invalid number" };
  }

  // Check for decimals
  if (!Number.isInteger(amountNumber)) {
    return { isValid: false, errorMessage: "Decimals are not allowed" };
  }

  // Check for negative numbers or 0
  if (amountNumber < minValue || amountNumber > maxValue) {
    return {
      isValid: false,
      errorMessage: `should be between ${minValue} and ${maxValue}`,
    };
  }

  return { isValid: true };
}

function validateName(name, minLength = 1, maxLength = 20) {
  const regex = /^[A-Za-z0-9_& ]+$/;

  if (typeof name !== "string") {
    return {
      isValid: false,
      errorMessage: "must be a string",
    };
  }

  if (name.length < minLength || name.length > maxLength) {
    return {
      isValid: false,
      errorMessage: `must be between ${minLength} and ${maxLength} characters long`,
    };
  }

  if (!regex.test(name)) {
    return {
      isValid: false,
      errorMessage:
        "must only contain alphanumeric characters, underscores, or spaces",
    };
  }

  return {
    isValid: true,
    errorMessage: "",
  };
}

function validatePercentage(amount, allowEmpty = false, min = 0, max = 1000) {
  // Remove initial '$' if present
  amount = String(amount).replace(/^\%/, "");

  // Remove thousand separators
  amount = amount.replace(/,/g, "");

  // check if empty
  if (allowEmpty && (!amount || amount === "")) {
    return {
      isValid: true,
      errorMessage: "",
    };
  }

  // Check if the amount is a valid number
  const amountNumber = strictParseFloat(amount);
  if (isNaN(amountNumber)) {
    return { isValid: false, errorMessage: "is not valid" };
  }

  // Check if within range
  if ((amountNumber < min) | (amountNumber > max)) {
    return {
      isValid: false,
      errorMessage: `should be between ${min} and ${max}`,
    };
  }

  return { isValid: true, errorMessage: "" };
}

function validatePhoneNumber(phoneNumber) {
  const regex = /^(\+?\d{6,})?$/;
  if (!regex.test(phoneNumber)) {
    return {
      isValid: false,
      errorMessage: `not valid`,
    };
  }
  return {
    isValid: true,
    errorMessage: "",
  };
}

function validateRatio(
  amount,
  allowEmpty = false,
  minValue = -1000,
  maxValue = 1000
) {
  // Remove thousand separators
  amount = String(amount).replace(/,/g, "");

  // check if empty
  if (allowEmpty && (!amount || amount === "")) {
    return {
      isValid: true,
      errorMessage: "",
    };
  }

  // Check if the amount is a valid number
  const amountNumber = strictParseFloat(amount);
  if (isNaN(amountNumber)) {
    return { isValid: false, errorMessage: "Invalid ratio" };
  }

  // Check for negative numbers or 0
  if (amountNumber < minValue || amountNumber > maxValue) {
    return {
      isValid: false,
      errorMessage: `should be between ${minValue} and ${maxValue}`,
    };
  }

  return { isValid: true };
}

function validateTime(timeString, startTime = "09:30", endTime = "17:00") {
  // HH:MM
  // If empty string, it's valid
  if (!timeString || timeString === "null") {
    return { isValid: true, errorMessage: "" };
  }

  // Regular expression to check time format
  const timeRegex = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/;
  const match = timeString.match(timeRegex);

  if (!match) {
    return {
      isValid: false,
      errorMessage: "is not a valid time format. Use HH:MM",
    };
  }

  // Extract time parts
  const [, hour, minute] = match;

  // Check if hour, minute, and second are valid
  if (
    strictParseInt(hour, 10) < 0 ||
    strictParseInt(hour, 10) > 23 ||
    strictParseInt(minute, 10) < 0 ||
    strictParseInt(minute, 10) > 59
  ) {
    return {
      isValid: false,
      errorMessage: "contains an invalid time component",
    };
  }

  // Check start/end time
  if (timeString < startTime || timeString > endTime) {
    return {
      isValid: false,
      errorMessage: `time should be between ${startTime} and ${endTime}`,
    };
  }

  return {
    isValid: true,
    errorMessage: "",
  };
}

function validateUrl(urlString) {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  if (!!pattern.test(urlString))
    return {
      isValid: true,
      errorMessage: "",
    };
  return {
    isValid: false,
    errorMessage: "is not a valid URL",
  };
}

export {
  validateAmount,
  validateDate,
  validateEmail,
  validateInteger,
  validateName,
  validatePercentage,
  validatePhoneNumber,
  validateRatio,
  validateTime,
  validateUrl,
};
