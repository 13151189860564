/**
 * @since 2023-05-14 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import "./InteractiveIcon.scss";

const InteractiveIcon = ({ Icon, onClick, tooltip, size = 20 }) => (
  <div
    className="styled-icon"
    onClick={onClick}
    data-tooltip={tooltip !== "" ? tooltip : "none"}
  >
    <Icon size={size} />
  </div>
);

export default InteractiveIcon;
