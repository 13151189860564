/**
 * @since 2023-10-30
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ToggleButtonGroupResearch from "./ToggleButtonGroupResearch";

export default ToggleButtonGroupResearch;
