/**
 * @since 2023-04-05
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import StrategyBuilder from "./StrategyBuilder";

export default StrategyBuilder;
