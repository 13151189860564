/**
 * @since 2023-10-17
 * @author Francesco Parrella
 * @maintainer Francesco Parrella
 * @copyright AlgoTraders, All rights reserved
 */

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Report from "components/Shared/Report";
import { useState } from "react";
import "./PanelReportViewer.scss";

const PanelReportViewer = ({ reportsData, showRecommendations = true }) => {
  //region States

  const [reportIndex, setReportIndex] = useState(0);

  //endregion

  //region Methods

  const handleReportChange = (event, newReportIndex) => {
    if (newReportIndex !== null) {
      setReportIndex(newReportIndex);
    }
  };

  const showReportSelector = () => {
    return (
      <ToggleButtonGroup
        className="panelReportButtonGroup"
        value={reportIndex}
        exclusive
        onChange={handleReportChange}
        aria-label="view"
        style={{
          marginRight: "0px",
          marginTop: "40px",
        }}
      >
        {Object.keys(reportsData).map((reportName, index) => (
          <ToggleButton value={index} aria-label={reportName}>
            {reportName}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  };

  //endregion

  return (
    <div className="panelReportViewer">
      {" "}
      <div
        style={{
          display: "flex",
          alignIitems: "center",
          justifySelf: "center",
        }}
      >
        {showReportSelector()}
      </div>
      <Report
        reportData={reportsData[Object.keys(reportsData)[reportIndex]]}
        showRecommendations={showRecommendations}
      />
    </div>
  );
};
export default PanelReportViewer;
